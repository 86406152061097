import React, { useState } from 'react';
import GenericCard from '../GenericProfile/GenericCard';
import GenericProfile from '../GenericProfile/GenericProfile';
import ShareModal from './ShareModal'; 
import useCurrentUser from '../../../hooks/useCurrentUser';

const Profile = ({ profileData, profileId, orderId, onEdit }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const currentUser = useCurrentUser(); // Récupération de l'utilisateur courant
  const userId = currentUser?.uid; 

  if (!profileData) {
    return <p>Loading profile data...</p>;
  }
  
  const handleViewProfile = () => {
    const profileUrl = `/virtualBusinessCard/${profileId}`;
    window.open(profileUrl, '_blank');
  };

  const handleEditProfile = () => {
    onEdit(profileId); 
  };

  const handleShare = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const statusColorMap = {
    "En attente de paiement": "bg-yellow-100 text-yellow-800",
    "En cours de création": "bg-blue-100 text-blue-800",
    "Livrée": "bg-green-100 text-green-800",
  };

  const statusIconMap = {
    "En attente de paiement": "⏳", 
    "En cours de création": "⚙️", 
    "Livrée": "✅", 
  };

  const statusColor = statusColorMap[profileData.status] || "bg-gray-100 text-gray-800";
  const statusIcon = statusIconMap[profileData.status] || "ℹ️"; 
  

  return (
    <div className='w-[320px] bg-violet-100 rounded-lg flex flex-col p-4 space-y-2'>
      <div>
        <p className={`text-sm font-semibold px-2 py-1 rounded-md shadow-md text-center flex items-center justify-center space-x-2 ${statusColor}`}>
          <span>{statusIcon}</span>
          <span>{profileData.status}</span>
        </p>
        <hr className="mb-1 border-gray-300 w-full" />
      </div>
      {/* Carte */}
      <div className='border border-gray-200 w-[290px] rounded-lg'>
        <GenericCard profileId={profileId} width={288} height={150} userId={userId} /> 
      </div>
      
      <div className="flex gap-4">
        <button className="bg-violet-600 text-white px-4 py-2 rounded hover:bg-violet-700" onClick={handleEditProfile}>Modifier</button>
        <button className="bg-violet-600 text-white px-4 py-2 rounded hover:bg-violet-700" onClick={handleViewProfile}>Voir</button>
        <button className="bg-white text-violet-600 px-4 py-2 rounded hover:bg-gray-50 border border-violet-600" onClick={handleShare}>Partager</button>
      </div>
      {/* Profil */}
      <div className='w-full'>
        <GenericProfile profileId={profileId} radius={50}  userId={userId} isSmallText={true}/>
      </div>
      
      <div className="flex flex-col bg-violet-100 w-full">
        {/* Ligne horizontale */}
        <hr className="mb-1 border-gray-300 w-full" />

        {/* Conteneur pour les textes */}
        <div className="flex flex-col space-y-2">
          <p className="text-sm font-bold font-sans text-violet-600">
            {profileData.fullName || "Nom Complet"}
          </p>
          <p className="text-xs font-bold font-sans">
            {profileData.lastModified
              ? new Date(profileData.lastModified).toLocaleString()
              : "Date non définie"}
          </p>
        </div>
      </div>
      
      <ShareModal isOpen={isModalOpen} onClose={closeModal} profileId={profileId} userId={userId} />
    </div>
  );
};

export default Profile;
