import { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { ref as storageRef, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../firebase';

const useProfileData = (profileId) => {
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        // Récupérer tous les documents de la collection profilesTest
        const profilesTestSnapshot = await getDocs(collection(db, 'profilesTest'));
        let foundProfile = null;
        let userId = null;

        // Parcourir chaque document pour chercher le profileId
        for (const doc of profilesTestSnapshot.docs) {
          const profileDataRef = collection(db, `profilesTest/${doc.id}/profilesData`);
          const profileDocSnap = await getDocs(profileDataRef);

          // Vérifier si le profil avec profileId existe dans cette sous-collection
          profileDocSnap.forEach(profileDoc => {
            if (profileDoc.id === profileId) {
              foundProfile = profileDoc.data();
              userId = doc.id; // Récupérer le userId depuis le document parent
            }
          });

          if (foundProfile) break; // Arrêter si le profil est trouvé
        }

        if (foundProfile) {
          // Construire les références aux fichiers dans Firebase Storage
          const profilePhotoRef = storageRef(storage, `images/${userId}/${profileId}/photoProfile.png`);
          const logoRef = storageRef(storage, `images/${userId}/${profileId}/logo.png`);

          // Initialiser les URLs
          let profilePhotoUrl = '';
          let logoUrl = '';

          // Récupérer les URLs des images
          try {
            profilePhotoUrl = await getDownloadURL(profilePhotoRef);
          } catch (error) {
            profilePhotoUrl = null; // Si l'image n'existe pas
          }

          try {
            logoUrl = await getDownloadURL(logoRef);
          } catch (error) {
            logoUrl = null; // Si l'image n'existe pas
          }

          // Mettre à jour les données du profil avec les URLs des images
          setProfileData({
            ...foundProfile,
            profilePhotoUrl: profilePhotoUrl || '',
            logoUrl: logoUrl || ''
          });
        } else {
          console.log('No such profile document!');
          setError('Profile document not found');
        }
      } catch (error) {
        console.error('Error fetching profile data:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfileData();

    // Nettoyage en cas de démontage du composant
    return () => {
      setProfileData(null);
      setLoading(true);
    };
  }, [profileId]);

  return { profileData, loading, error };
};

export default useProfileData;
