import { useEffect, useState } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase'; // Assurez-vous que ce chemin est correct
import useCurrentUser from './useCurrentUser'; // Assurez-vous que ce chemin est correct

const useUserProfileIds = () => {
  const [profileIds, setProfileIds] = useState([]);
  const currentUser = useCurrentUser(); 

  useEffect(() => {
    if (currentUser) {
      // Référence à la sous-collection 'profilesData' sans filtrer par 'userId'
      const profilesRef = collection(db, 'profilesTest', currentUser.uid, 'profilesData');
      
      // Écoutez les mises à jour en temps réel
      const unsubscribe = onSnapshot(profilesRef, (querySnapshot) => {
        const ids = querySnapshot.docs.map(doc => doc.id);
        setProfileIds(ids);
      }, (error) => {
        console.error('Error fetching profile IDs:', error);
      });

      // Nettoyez l'abonnement lorsque le composant est démonté
      return () => unsubscribe();
    } else {
      // Réinitialiser profileIds si currentUser n'est pas disponible
      setProfileIds([]);
    }
  }, [currentUser]);

  return profileIds;
};

export default useUserProfileIds;
