import React from 'react';
import useAllOrdersData from '../../../hooks/useAllOrdersData'; // Assurez-vous que le chemin est correct

const OrderTracking = () => {
  const orders = useAllOrdersData(); // Utiliser le hook pour récupérer les commandes

  return (
    <div className='p-4'>
      <h1 className="text-xl md:text-2xl font-bold mb-4 text-gray-800">Suivi des Commandes</h1>
      <div className="relative overflow-x-auto">
        {/* Affichage des commandes sous forme de carte sur mobile */}
        <div className="block sm:hidden">
          {orders.length > 0 ? (
            orders.map((order) => (
              <div key={order.id} className="p-4 mb-4 border rounded-lg shadow-md bg-white">
                <h2 className="text-base font-bold text-gray-500">ID de Commande: <span className="text-black">{order.id}</span></h2>
                <p className="text-gray-500">Date: <span className="text-black">{new Date(order.createdAt).toLocaleString('fr-FR')}</span></p>
                <p className="text-gray-500">Statut: <span className="text-black">{order.status}</span></p>
                <p className="text-gray-500">Nombre de Carte: <span className="text-black" >{order.userCount}</span></p>
                <p className="text-gray-500">Montant: <span className="text-black">{order.totalPrice.toFixed(2)} MAD</span></p>
              </div>
            ))
          ) : (
            <p className="text-center">Aucune commande trouvée</p>
          )}
        </div>

        {/* Affichage sous forme de tableau sur desktop */}
        <div className="hidden sm:block">
          <table className="min-w-full text-sm text-left text-gray-600">
            <thead className='text-xs text-gray-700 uppercase bg-violet-200'>
              <tr>
                <th scope="col" className="px-6 py-3 border-b">ID de Commande</th>
                <th scope="col" className="px-6 py-3 border-b">Date</th>
                <th scope="col" className="px-6 py-3 border-b">Statut</th>
                <th scope="col" className="px-6 py-3 border-b">Nombre de Carte</th>
                <th scope="col" className="px-6 py-3 border-b">Montant</th>
              </tr>
            </thead>
            <tbody>
              {orders.length > 0 ? (
                orders.map((order) => (
                  <tr key={order.id} className='odd:bg-gray-50 even:bg-gray-100 border-b'>
                    <td className="px-6 py-4">{order.id}</td>
                    <td className="px-6 py-4">{new Date(order.createdAt).toLocaleString('fr-FR')}</td>
                    <td className="px-6 py-4">{order.status }</td>
                    <td className="px-6 py-4">{order.userCount}</td>
                    <td className="px-6 py-4">{order.totalPrice.toFixed(2)} MAD</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="border border-gray-300 p-4 text-center">Aucune commande trouvée</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default OrderTracking;
