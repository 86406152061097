// Import des modules Firebase nécessaires
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore, doc, updateDoc, getDoc, setDoc, addDoc ,collection} from 'firebase/firestore';
import { getStorage, ref as storageRef, uploadBytes } from 'firebase/storage';

// Configuration Firebase
const firebaseConfig = {
  apiKey: "AIzaSyCIt4hU9gpjZoYsLnJyUTKgAeG7CVtJJ0U",
  authDomain: "elitecard-8a765.firebaseapp.com",
  projectId: "elitecard-8a765",
  storageBucket: "elitecard-8a765.appspot.com",
  messagingSenderId: "1082199943492",
  appId: "1:1082199943492:web:158aa3672438c1805c8f11",
  measurementId: "G-SNCSDXBQDN"
};

// Initialisation de Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const provider = new GoogleAuthProvider();
const storage = getStorage(app); // Initialisation de Firebase Storage

// Export des modules nécessaires
export { auth, provider, app, db, analytics, storage, storageRef, collection, uploadBytes, getAuth, getFirestore, getStorage, doc, updateDoc, getDoc, setDoc, addDoc };
  
// Alias for Firestore
export { db as firestore };

export default app;
