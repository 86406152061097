import React, { useState, useEffect, useRef  } from 'react';
import useAllProfilesData from '../../../hooks/useAllProfilesData';
import { FiSearch } from 'react-icons/fi'; 
import Profile from './Profile';
import { FaPlus } from "react-icons/fa6";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import ProfileEdit from '../ProfileEdit/ProfileEdit'; 
import ProfileForm from '../ProfileForm/Profile'; 
import { IoFilter } from 'react-icons/io5';

const Profiles = ({ orderId, userCount, profileCount }) => {
  const profiles = useAllProfilesData();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [sortOption, setSortOption] = useState('lastModified');
  const [isEditing, setIsEditing] = useState(false);
  const [profileToEditId, setProfileToEditId] = useState(null);
  const [isAddingProfile, setIsAddingProfile] = useState(false); 
  const [showFilterMenu, setShowFilterMenu] = useState(false); 
  const filterMenuRef = useRef(null); 
  const filterOptions = [
    { label: 'Nom Complet', value: 'fullName' },
    { label: 'Date de Création', value: 'lastModified' }
  ];
  
  const profilesPerPage = window.innerWidth >= 768 ? 3 : 1;
  const navigate = useNavigate();

  const handleEditProfile = (profileId) => {
    setProfileToEditId(profileId);
    setIsEditing(true);
  };

  const goToProfileForm = () => {
    if (profileCount < userCount) {
      setIsAddingProfile(true); 
    } else {
      navigate('/');
      setTimeout(() => {
        const element = document.getElementById('offre');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
  };

  useEffect(() => {
    const filtered = profiles.filter(profile =>
      profile.fullName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const sorted = filtered.sort((a, b) => {
      if (sortOption === 'fullName') {
        return a.fullName.localeCompare(b.fullName);
      } else if (sortOption === 'lastModified') {
        return new Date(b.lastModified) - new Date(a.lastModified);
      }
      return 0;
    });

    setFilteredProfiles(sorted);
  }, [searchTerm, profiles, sortOption]);
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const isEditingParam = queryParams.get('isEditing');
    const profileIdParam = queryParams.get('profileId');
  
    if (isEditingParam && profileIdParam) {
      setIsEditing(true);
      setProfileToEditId(profileIdParam);
    }
  }, []);
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterMenuRef.current && !filterMenuRef.current.contains(event.target)) {
        setShowFilterMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [filterMenuRef]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  const startIndex = currentPage * profilesPerPage;
  const endIndex = startIndex + profilesPerPage;
  const currentProfiles = filteredProfiles.slice(startIndex, endIndex);

  const totalPages = Math.ceil(filteredProfiles.length / profilesPerPage);

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const goToNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  const renderProfileList = () => (
    <div className="flex flex-col md:flex-row min-h-full">
      <div className="relative flex-1 p-4 flex flex-col">
        <div className="flex flex-col items-center">
          <div className="mb-4 flex items-center flex-row"> 
            {/* Search and Sort for desktop */}
            <div className='hidden md:flex flex-row'>
              {/* Search Bar */}
              <div className="flex items-center border border-gray-300 rounded-md px-4 py-2 bg-white w-full md:w-80 transition duration-300 ease-in-out transform hover:scale-105 hover:border-violet-500 focus-within:border-violet-500 focus-within:ring-2 focus-within:ring-violet-200">
                <FiSearch className="text-gray-500" />
                <input
                  type="text"
                  placeholder="Rechercher par Nom Complet"
                  className="ml-2 w-full focus:outline-none"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>

              {/* Sort Dropdown */}
              <div className="flex items-center h-10 mb-4 flex-col md:flex-row md:ml-6">
                <p className="text-gray-600 mr-4">Trier par:</p>
                <select
                  value={sortOption}
                  onChange={handleSortChange}
                  className="border border-gray-300 rounded-md px-4 py-2 bg-white transition duration-300 ease-in-out text-gray-500 transform hover:scale-105 hover:border-violet-500 focus-within:border-violet-500 focus-within:ring-2 focus-within:ring-violet-200"
                >
                  <option value="fullName" className="text-gray-500 text-sm md:text-base">Nom Complet</option>
                  <option value="lastModified" className="text-gray-500 text-sm md:text-base">Date de Création</option>
                </select>
              </div>
            </div>

            {/* Filter Button and Menu for Mobile */}
<div className="justify-between space-x-44 items-center w-full mb-2 flex md:hidden ">
  {/* Filter Button for Mobile */}
  <div className="">
    <button onClick={() => setShowFilterMenu(!showFilterMenu)} className="flex items-center p-2">
      <IoFilter className="text-gray-500 w-8 h-8" />
    </button>
    {showFilterMenu && (
      <div ref={filterMenuRef} className="absolute bg-white border border-gray-300 rounded-md shadow-lg p-2">
        <div className="flex items-center mb-4">
          <FiSearch className="text-gray-500 mr-2" />
          <input
            type="text"
            placeholder="Rechercher par Nom Complet"
            className="w-full focus:outline-none"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        <div className="flex flex-col">
          {filterOptions.map((option, index) => (
            <button
              key={index}
              onClick={() => {
                setSortOption(option.value);
                setShowFilterMenu(false);
              }}
              className="text-left px-4 py-1 hover:bg-gray-100"
            >
              {option.label}
            </button>
          ))}
        </div>
      </div>
    )}
  </div>
  
  {/* Button "Ajouter" for mobile */}
  <div className="items-center">
    <button 
      onClick={goToProfileForm} 
      className="flex items-center justify-center p-2 bg-violet-500 rounded-full hover:bg-violet-600 transition duration-300 ease-in-out w-12 h-12"
    >
      <FaPlus className="text-white text-2xl" />
    </button>
  </div>
</div>

          </div>
          {/* Pagination */}
          <div className="flex justify-center">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => goToPage(index)}
                className={`mx-1 px-3 py-1 border rounded ${index === currentPage ? 'bg-violet-500 text-white' : 'bg-white text-gray-500'}`}
              >
                {index + 1}
              </button>
            ))}
          </div>
          <div className="flex justify-between w-full mb-4">
            <button 
              onClick={goToPreviousPage}
              disabled={currentPage === 0}
              className={`p-2 rounded-full transition duration-300 ease-in-out h-9 w-9 ${
                currentPage === 0 ? 'bg-gray-300 text-gray-500' : 'bg-violet-500 text-white hover:bg-violet-600'
              }`}
            >
              <FaArrowLeft className={`text-xl ${currentPage === 0 ? 'text-gray-400' : 'text-white'}`} />
            </button>
            <button 
              onClick={goToNextPage}
              disabled={currentPage >= totalPages - 1}
              className={`p-2 rounded-full transition duration-300 ease-in-out h-9 w-9 ${
                currentPage >= totalPages - 1 ? 'bg-gray-300 text-gray-500' : 'bg-violet-500 text-white hover:bg-violet-600'
              }`}
            >
              <FaArrowRight className={`text-xl ${currentPage >= totalPages - 1 ? 'text-gray-400' : 'text-white'}`} />
            </button>
          </div>

          {/* Profiles Display */}
          <div className="hidden md:grid md:grid-cols-3 md:gap-4">
            {currentProfiles.length > 0 ? (
              currentProfiles.map(profile => (
                <div key={profile.id} className="w-full">
                  <Profile 
                    profileData={profile} 
                    profileId={profile.id} 
                    orderId={profile.orderId} 
                    onEdit={handleEditProfile} 
                  />
                </div>
              ))
            ) : (
             <div className='ml-80 justify-center items-center w-full '>
               <p>Aucun profil trouvé!</p>
               <p>Cliquez sur le bouton plus pour commencer votre aventure 😉.</p>
             </div>
            )}
          </div>
          <div className="grid grid-cols-1 gap-4 md:hidden">
            {currentProfiles.length > 0 ? (
              currentProfiles.map(profile => (
                <div key={profile.id} className="w-full">
                  <Profile 
                    profileData={profile} 
                    profileId={profile.id} 
                    orderId={profile.orderId} 
                    onEdit={handleEditProfile} 
                  />
                </div>
              ))
            ) : (
              <div className=''>
               <p>Aucun profil trouvé!</p>
               <p>Cliquez sur le bouton plus pour commencer votre aventure.</p>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Button "Ajouter" for desktop */}
      <div className="absolute top-4 right-4 md:relative md:w-1/12 md:h-screen md:flex md:items-center md:justify-center hidden ">
        <button 
          onClick={goToProfileForm} 
          className="flex items-center justify-center p-2 bg-violet-500 rounded-full hover:bg-violet-600 transition duration-300 ease-in-out w-14 h-14"
        >
          <FaPlus className="text-white text-2xl" />
        </button>
      </div>
    </div>
  );

  const renderAddProfileForm = () => (
    <ProfileForm 
      onSave={() => setIsAddingProfile(false)} 
      onCancel={() => setIsAddingProfile(false)} 
      statut={true}
      orderId={orderId} 
    />
  );

  const renderEditForm = () => (
    <ProfileEdit 
      orderId={orderId} 
      onSave={() => setIsEditing(false)} 
      profileId={profileToEditId}
      onCancel={() => setIsEditing(false)}
    />
  );

  return (
    <div>
      {isEditing ? renderEditForm() : isAddingProfile ? renderAddProfileForm() : renderProfileList()}
    </div>
  );
};

export default Profiles;
