import React, { useState, useEffect } from 'react';
import { FiSearch } from 'react-icons/fi';

const Help = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [hoveredQuestion, setHoveredQuestion] = useState(null);

  const list = [
    { id: 1, 
      question: 'Comment puis-je partager ma carte de visite numérique ?', 
      answer: 'Notre équipe EliteCard vous fournit une carte de visite digitale équipée de la technologie NFC et d\'un code QR. Vous pouvez utiliser cette carte pour partager facilement vos informations en la scannant ou en l\'approchant d\'un appareil compatible NFC. Le code QR peut également être inclus dans votre signature email, votre CV, votre portfolio, et bien plus encore.'
    },
    { id: 2, question: 'Quelles informations puis-je inclure sur ma carte de visite numérique ?', answer: 'Votre carte de visite numérique peut contenir votre nom, titre professionnel, entreprise, numéro de mobile, adresse email, images, liens vers les réseaux sociaux, et liens web. Vous pouvez également ajouter du texte personnalisé et à votre carte de visite numérique.' },
    { id: 3, question: 'Puis-je modifier les informations sur ma carte de visite numérique ?', answer: 'Une carte de visite numérique est dynamique par défaut, ce qui signifie que vous pouvez modifier les informations en temps réel sans avoir à réimprimer le code QR. Vous pouvez ainsi changer votre numéro de téléphone, adresse email ou d\'autres informations tout en utilisant le même code QR.' },
    { id: 4, question: 'Pourquoi devrais-je utiliser une carte de visite numérique ?', answer: 'Une carte de visite numérique améliore les capacités de vos cartes de visite traditionnelles. Elle vous permet de partager des informations professionnelles plus détaillées, et vous pouvez également modifier les détails en temps réel sans avoir à réimprimer le code QR ou la carte de visite.' },
  ];

  useEffect(() => {
    setFilteredProfiles(
      list.filter(item => 
        item.question.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, list]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="p-4 h-screen">
      <div className='bg-violet-500 p-4 rounded-lg h-1/3 flex flex-col items-center'>
        <p className="text-white mb-4 font-bold font-mono mt-16 text-center text-lg md:text-xl">
          Bienvenue, Comment pouvons-nous vous aider aujourd'hui ?
        </p>
        <div className="mb-4 flex items-center border border-gray-300 rounded-md px-4 py-2 bg-white w-full max-w-lg transition duration-300 ease-in-out transform hover:scale-105 hover:border-violet-500 focus-within:border-violet-500 focus-within:ring-2 focus-within:ring-violet-200">
          <FiSearch className="text-gray-500" />
          <input
            type="text"
            placeholder="Rechercher en utilisant mots clés"
            className="ml-2 w-full focus:outline-none"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <div className='bg-gray-100 p-4 rounded-lg'>
        <p className="text-gray-600 mb-2 font-mono font-semibold text-center">
          Vous pouvez trouver ici des réponses aux questions fréquemment posées et obtenir de l'assistance pour utiliser notre application.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {filteredProfiles.map(item => (
            <div
              key={item.id}
              className="relative bg-white border border-gray-300 rounded-lg p-4 shadow-md hover:shadow-lg transition-shadow duration-300"
              onMouseEnter={() => setHoveredQuestion(item.id)}
              onMouseLeave={() => setHoveredQuestion(null)}
            >
              <p className="font-semibold text-lg text-gray-500">{item.question}</p>
              {hoveredQuestion === item.id && (
                <p className="mt-2 text-gray-400">{item.answer}</p>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="flex-grow flex flex-col items-center justify-center bg-gray-100 space-y-5">
        <h2 className="text-center text-lg md:text-xl">Vous avez une autre question ?</h2>
        <form className='flex flex-col'>
          <a 
            href="mailto:contact@elitecard.ma"
            className="bg-violet-600 text-white py-2 px-4 rounded-md shadow-md hover:bg-violet-700 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-opacity-50 w-64 text-center"
          >
            Envoyer un email à notre support
          </a>
        </form>
      </div>
    </div>
  );
};

export default Help;
