import React from 'react';
import { useParams } from 'react-router-dom';
import GenericProfile from '../GenericProfile/GenericProfile';
import useProfileData from '../../../hooks/useProfileData';
//import { eliteCardUrl } from '../../../constants';
import { getProfileUrl } from '../../../constants';
import { Helmet } from 'react-helmet';
const VirtualBusinessCard = () => {
  const {userId, profileId } = useParams();
  const profileData = useProfileData(profileId);

  if (!profileId) {
    console.error('Profile ID not found');
    return <div>Profile ID not found</div>;
  }

  const handleDownloadVCF = () => {
    if (!profileData || !profileData.profileData) {
      console.error('Profile data not loaded');
      return;
    }
  
    const { fullName, title, departement, company, aboutMe, fields = [] } = profileData.profileData;
  
    // Créer le contenu VCF
    const vcfContent = 
`BEGIN:VCARD
VERSION:3.0
FN:${fullName || '-'}
UID:${profileId}
${fields
  .map(field => field.instances.map(instance => {
    if (field.name === 'email') {
      return `EMAIL;TYPE=${instance.label.toUpperCase()}:${instance.value.trim()}`;
    }
    if (field.name === 'phone') {
      return `TEL;TYPE=${instance.label.toUpperCase()}:${instance.value.trim()}`;
    }
    if (field.name !== 'phone' && field.name !== 'email') {
      return `URL;TYPE=${field.name.toUpperCase()}:${instance.value.trim()}`;
    }
    return '';
  }).join('\n')).join('\n')}
ORG:${company || 'Company'};${departement || 'Département Inconnu'}
TITLE:${title || '-'}
NOTE:${aboutMe || '-'}
N:${fullName ? fullName.split(' ')[1].trim() : ''};${fullName ? fullName.split(' ')[0].trim() : ''};
END:VCARD
`;
  
    const blob = new Blob([vcfContent], { type: 'text/vcard' });
    const url = URL.createObjectURL(blob);
  
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fullName || 'contact'}.vcf`; 
    a.click();
  
    URL.revokeObjectURL(url); // Nettoyer l'objet URL
  };
  
  return (
    <div className="flex flex-col items-center justify-center  bg-gradient-to-r from-blue-500 to-purple-500 relative space-y-32 min-h-screen">
      <Helmet>
        <title>EliteCard</title>
        <meta name="description" content="Découvrez et partagez votre carte de visite numérique avec EliteCard. Simplifiez vos échanges professionnels avec une carte moderne et dynamique." />
        <meta name="keywords" content="business card, digital, seo" />
        <meta name="author" content="EliteCard" />
        <meta property="og:title" content="EliteCard.ma" data-react-helmet="true"/>
        <meta property="og:description" content="Découvrez et partagez votre carte de visite numérique avec EliteCard. Simplifiez vos échanges professionnels avec une carte moderne et dynamique." data-react-helmet="true"/>
        <meta property="og:image" content="https://elitecard.ma/images/icon.png" data-react-helmet="true"/>
        <meta property="og:url" content={getProfileUrl} data-react-helmet="true"/>
        <meta name="twitter:title" content="EliteCard.ma" />
        <meta name="twitter:description" content="Découvrez et partagez votre carte de visite numérique avec EliteCard. Simplifiez vos échanges professionnels avec une carte moderne et dynamique." />
        <meta name="twitter:image" content="https://elitecard.ma/images/icon.png" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='p-8 md:w-1/3'>
        <GenericProfile profileId={profileId} userId={userId}/>
      </div>
      
      <div className="flex flex-col items-center justify-center">
        <div className="relative flex flex-col items-center justify-center">
          <button 
            className="bg-violet-600 rounded-t-lg text-white text-xs leading-8 max-w-screen-md mx-auto bottom-0 fixed opacity-85 text-center w-56 py-2 px-4 rounded-lg z-10"
            style={{ zIndex: '10', marginBottom: '4rem' }} 
            onClick={handleDownloadVCF}
          >
            Ajouter Aux Contacts
          </button>
        </div>
        <a
          className="bg-violet-500 rounded-t-3xl text-white text-xs leading-8 max-w-screen-md mx-auto bottom-0 fixed opacity-85 text-center w-full z-10"
          href={process.env.REACT_APP_URL}
          rel="noopener noreferrer"
          target="_blank"
          style={{ zIndex: '10' }} 
        >
          <div className="overflow-hidden rounded-t-3xl">
            <p className="text-xs bg-violet-500 text-white leading-8">Une carte de visite numérique de</p>
            <p className="text-sm text-white">EliteCard</p>
          </div>
        </a>
      </div>
    </div>
  );
};

export default VirtualBusinessCard;
