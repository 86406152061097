// FieldsEdit.jsx
import React, { useState } from 'react';
import FieldsListEdit from './FieldsListEdit';
import FieldsFormEdit from './FieldsFormEdit';
import PropTypes from 'prop-types';

const FieldsEdit = ({ fields, onUpdate, orderId, currentUserId, fileUrl, setFileUrl }) => {
  const [selectedFields, setSelectedFields] = useState(fields);

  const handleSelectField = (field) => {
    if (!selectedFields.find((f) => f.name === field.name)) {
      setSelectedFields([...selectedFields, { ...field, instances: [{ value: '' }] }]);
    } else {
      const updatedFields = selectedFields.map((f) =>
        f.name === field.name ? { ...f, instances: [...f.instances, { value: '' }] } : f
      );
      setSelectedFields(updatedFields);
    }
  };
  const handleFieldUpdate = (fieldName, instanceIndex, data) => {
    const updatedFields = selectedFields.map((field) => {
      if (field.name === fieldName) {
        const updatedInstances = field.instances.map((instance, index) =>
          index === instanceIndex ? { ...instance, ...data } : instance
        );
        return { ...field, instances: updatedInstances };
      }
      return field;
    });
    setSelectedFields(updatedFields);
    onUpdate({ fields: updatedFields }); // Mise à jour des données dans le composant parent (ProfileEdit)
  };
  const handleCancelField = (fieldName, instanceIndex) => {
    const updatedFields = selectedFields.map((field) => {
      if (field.name === fieldName) {
        const updatedInstances = field.instances.filter((instance, index) => index !== instanceIndex);
        if (updatedInstances.length === 0) {
          // Si toutes les instances de ce champ sont supprimées, supprimez le champ
          return null;
        }
        return { ...field, instances: updatedInstances };
      }
      return field;
    }).filter(field => field !== null); // Filtrer les champs qui ont été supprimés
    setSelectedFields(updatedFields);
    onUpdate({ fields: updatedFields }); // Mise à jour des données dans le composant parent (Profile)
  };

  return (
    <div className="flex flex-col md:flex-row">
      <div className='w-full md:w-1/3 rounded-md mb-4 md:mb-0'>
        <FieldsListEdit onSelectField={handleSelectField} />
      </div>
      <div className="flex flex-wrap md:flex-col border-dashed border-2 border-gray-400 p-2 md:p-4 bg-gray-100 w-full md:w-2/3 ml-0 md:ml-4 rounded-md">
        {selectedFields.map((field) =>
          field.instances.map((instance, instanceIndex) => (
            <div className="w-full mb-2" key={`${field.name}-${instanceIndex}`}>
              <FieldsFormEdit
                key={`${field.name}-${instanceIndex}`}
                selectedField={field}
                instanceIndex={instanceIndex}
                profileData={fields}
                onUpdate={handleFieldUpdate}
                onCancel={handleCancelField} // Passer la fonction handleCancelField à FieldsForm
                orderId={orderId}
                currentUserId={currentUserId}
                fileUrl={instance.value}
                setFileUrl={setFileUrl}
                className="w-full"
              />
            </div> 
          ))
        )}
      </div>
    </div>
  );
};
FieldsEdit.propTypes = {
  fields: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default FieldsEdit;
