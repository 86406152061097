import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import QRCode from 'qrcode.react';
import GenericCard from '../GenericProfile/GenericCard';
import { getProfileUrl } from '../../../constants';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
} from 'react-share';
import GenericProfile from '../GenericProfile/GenericProfile';
import useProfileData from '../../../hooks/useProfileData';
import useOrderData from '../../../hooks/useOrderData';
import useCurrentUser from '../../../hooks/useCurrentUser';

const ThankYouPage = () => {
  const { orderId, profileId } = useParams();
  const currentUser = useCurrentUser();
  const userId = currentUser?.uid; 
  const orderData = useOrderData(orderId); // Utilisation du hook pour récupérer les données de la commande
  const profileData = useProfileData(profileId);
  const navigate = useNavigate();

  if (!orderData || !profileData) {
    return <div>Loading...</div>;
  }

  const userCount = orderData.userCount || 0; // Supposons que userCount est une propriété de orderData
  const shareUrl = getProfileUrl(profileId);

  const handleViewProfile = () => {
    const profileUrl = `/virtualBusinessCard/${profileId}`;
    window.open(profileUrl, '_blank');
  };

  const handleEditProfile = () => {
    navigate(`/espaceClient?page=profiles&isEditing=true&profileId=${profileId}`);    
  };

  const handleHome = () => {
    navigate(`/espaceClient`);
  };
  const handleOrderTracking = () => {
    navigate(`/espaceClient?page=orders`);
  };
  

  const copyLinkToClipboard = () => {
    const link = shareUrl;
    const input = document.createElement('input');
    input.style.position = 'fixed';
    input.style.opacity = 0;
    input.value = link;
    document.body.appendChild(input);
    input.select();
    input.setSelectionRange(0, 99999); /* Pour les mobiles */
    document.execCommand('copy');
    document.body.removeChild(input);
    alert('Lien copié dans le presse-papiers !');
  };

  return (
    <div className="flex flex-col md:flex-row p-4 bg-gradient-to-r from-blue-500 to-purple-500">
      {/* Texte en haut */}
      <div className="fixed top-0 left-0 w-full bg-gradient-to-r from-blue-500 to-purple-500 text-center p-6 text-white z-10 shadow-lg">
        <p className="text-xl font-semibold leading-relaxed">
          {userCount > 1 ? (
            <>
              <span className="text-2xl font-bold font-sans">Merci</span> pour votre choix de <span className="font-bold">EliteCard</span>. Voici les détails de votre premier profil. Pour compléter les autres profils, veuillez accéder à{' '}
              <a 
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleHome();
                }}
                className="text-yellow-200 hover:underline font-bold"
              >
                votre espace client
              </a>
              .
            </>
          ) : (
            <>
              <span className="text-2xl font-bold font-sans">Merci</span> pour votre choix de <span className="font-bold">EliteCard</span>. Voici les détails de votre profil.
            </>
          )}
        </p>
      </div>
      {/* Partie Gauche */}
      <div className="flex-1 md:w-1/2 p-4 flex flex-col items-center mt-24 md:ml-16 ml-0">
        {/* Affichage de la carte pour mobile */}
        <div className='block md:hidden flex-col space-y-4'>
          <div className="border border-gray-200 w-[290px] rounded-lg ">
            <GenericCard profileId={profileId} width={288} height={150} userId={userId} /> 
          </div>
          <button
              className="bg-purple-600 w-full text-white px-4 py-2 rounded transition-all duration-300 ease-in-out hover:bg-white hover:text-purple-600 hover:border-purple-600 transform hover:scale-105"
              onClick={handleOrderTracking}
            >
            Suivre ma commande
          </button>
        </div>
        
         {/* Affichage de la carte pour desktop */}
        <div className="flex-col space-y-4 p-4 w-full max-w-md hidden md:block">
          {/* Carte */}
          <GenericCard profileId={profileId} userId={userId} />
          <button
            className="bg-purple-600 text-white px-4 py-2 w-full rounded transition-all duration-300 ease-in-out hover:bg-white hover:text-purple-600 hover:border-purple-600 transform hover:scale-105"
            onClick={handleOrderTracking}
          >
            Suivre ma commande
          </button>
        </div>
        {/* Affichage du code QR */}
        <div className="bg-white shadow-md rounded-lg p-6 mt-4 w-full max-w-md">
          <div className="flex flex-col items-center mb-4 border border-gray-400 rounded-lg p-4">
            <p className="font-bold text-center mb-2">QR Code</p>
            <QRCode value={shareUrl} className='w-32 h-32 mx-auto' />
          </div>
          <div className="mt-4 text-center">
            <p className='text-purple-600 mt-2'>Scanner Code QR ou Partager</p>
            <div className="flex justify-center space-x-2 mt-4">
              <FacebookShareButton url={shareUrl}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton url={shareUrl}>
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <LinkedinShareButton url={shareUrl}>
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
              <WhatsappShareButton url={shareUrl}>
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
            </div>
            <button className="bg-white border border-violet-500 text-violet-500 px-4 py-2 rounded transition-all duration-300 ease-in-out hover:bg-violet-100 transform hover:scale-105 mt-5" onClick={copyLinkToClipboard}>
              Copier Lien
            </button>
          </div>
        </div>
      </div>
  
      {/* Partie Droite */}
      <div className="flex flex-col md:w-1/2 p-8 items-center justify-center md:mt-24 mt-4">
        {/* Affichage du profil */}
        <div className='w-full md:w-2/3'>
          <GenericProfile profileId={profileId} userId={userId} />
        </div>
  
        <div className="mt-4 flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 mb-4">
          <button className="bg-purple-600 text-white px-4 py-2 rounded transition-all duration-300 ease-in-out hover:bg-white hover:text-purple-600 hover:border-purple-600 transform hover:scale-105" onClick={handleViewProfile}>
            Voir le profil
          </button>
          <button className="bg-white border border-violet-500 text-violet-500 px-4 py-2 rounded transition-all duration-300 ease-in-out hover:bg-violet-100 transform hover:scale-105" onClick={handleEditProfile}>
            Éditer le profil
          </button>
        </div>
        <button className="bg-purple-600 text-white px-4 py-2 rounded transition-all duration-300 ease-in-out hover:bg-white hover:text-purple-600 hover:border-purple-600 transform hover:scale-105" onClick={handleHome}>
          Mon Espace Client
        </button>
      </div>
    </div>
  );
};

export default ThankYouPage;
