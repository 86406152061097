import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AiOutlineMail, AiOutlinePhone, AiOutlineGlobal, AiOutlineFacebook, AiOutlineInstagram } from 'react-icons/ai';
import { FaLinkedin, FaSnapchatGhost, FaPinterestSquare, FaTelegram, FaTiktok, FaWhatsapp, FaYoutube, FaLink, FaMapMarkerAlt, FaFilePdf, FaCloudUploadAlt } from 'react-icons/fa';
import { CiCircleList } from "react-icons/ci";
import { storage } from '../../../firebase'; 
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';

const FieldsFormEdit = ({ selectedField, instanceIndex, onUpdate, onCancel, orderId, currentUserId, fileUrl, setFileUrl }) => {
  const [fieldValue, setFieldValue] = useState(selectedField.instances[instanceIndex].value);
  const [label, setLabel] = useState('');
  const [file, setFile] = useState(null);
  const [localFileUrl, setLocalFileUrl] = useState(fileUrl);
  const [validationError, setValidationError] = useState('');
  const [isLabelTouched, setIsLabelTouched] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  
  useEffect(() => {
    setLabel(selectedField.instances[instanceIndex].label || '');
  }, [selectedField, instanceIndex, fieldValue]);

  const labelsOptions = {
    email: ['Personnel', 'Professionnel'],
    phone: ['Domicile', 'Travail', 'Mobile', 'Principal', 'Fax'],
    address: ['Domicile', 'Travail']
  };
  const handleBlur = () => {
    setIsLabelTouched(true);
    setIsValidated(true); // Marquer le champ comme validé
  };
  const isLabelValid = label.trim() !== ''; 

  useEffect(() => {
    if (selectedField.name === 'pdf') {
      setLocalFileUrl(fileUrl);
    }
  }, [selectedField, fileUrl]);
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };
  const validateField = (name, value) => {
    let regex;
    switch (name) {
      case 'linkedin':
        regex = /^(https?:\/\/)?(www\.)?linkedin\.com\/.*$/i;
        break;
      case 'youtube':
        regex = /^(https?:\/\/)?(www\.)?youtube\.com\/(c\/|channel\/|user\/)?.+$/i;
        break;
      case 'instagram':
        regex = /^(https?:\/\/)?(www\.)?instagram\.com\/.+$/i;
        break;
      case 'facebook':
        regex = /^(https?:\/\/)?(www\.|web\.)?facebook\.com\/.+$/i;
        break;
      case 'tiktok':
        regex = /^(https?:\/\/)?(www\.)?tiktok\.com\/@.+$/i;
        break;
      case 'snapchat':
        regex = /^(https?:\/\/)?(www\.)?snapchat\.com\/add\/.+$/i;
        break;
      case 'pinterest':
        regex = /^(https?:\/\/)?(www\.)?pinterest\.fr\/.*|^(https?:\/\/)?(www\.)?pinterest\.com\/.*$/i;
        break;
      case 'telegram':
        regex = /^(https?:\/\/)?(t\.me\/|t\.me\/joinchat\/).+$/i;
        break;
      case 'phone':
        regex = /^[+]?[0-9\s]*$/
        break;
      case 'whatsapp':
        regex = /^[+]?[0-9\s]*$/
        break;
      case 'email':
        return validateEmail(value);
      default:
        return true;
    }
    return regex.test(value);
  };   

  const handleChange = async (e) => {
    const { name, value, files } = e.target;
    setValidationError('');
    setIsValidated(false);
    if (name === selectedField.name) {
      setFieldValue(value);
      if (validateField(name, value)) { 
        onUpdate(selectedField.name, instanceIndex, { value, label });
      } else {
        switch (selectedField.name) {
          case 'email':
            setValidationError('E-mail invalide');
            break;
          case 'phone':
            setValidationError('Numéro de téléphone invalide');
            break;
          case 'whatsapp':
            setValidationError('Whatsapp invalide');
            break;
          case 'linkedin':
          case 'youtube':
          case 'instagram':
          case 'facebook':
          case 'tiktok':
          case 'website':
          case 'link':
          case 'snapchat':
          case 'pinterest':
          case 'telegram':
            setValidationError('URL invalide');
            break;
          default:
            setValidationError('Valeur invalide');
        }
      }
      onUpdate(selectedField.name, instanceIndex, { value, label });
    } else if (name === `${selectedField.name}-label`) {
      setLabel(value);
      onUpdate(selectedField.name, instanceIndex, { value: fieldValue, label: value });
    } else if (name.startsWith('file-upload-') && files.length > 0) {
      const selectedFile = files[0];
      setFile(selectedFile);

      // Upload the file to Firebase Storage
      const fileRef = storageRef(storage, `files/${currentUserId}/${orderId}/${selectedFile.name}`);
      await uploadBytes(fileRef, selectedFile);

      // Get the download URL
      const url = await getDownloadURL(fileRef);
      setFieldValue(url); // Set the field value to the URL
      setFileUrl(url); // Update the fileUrl state
      onUpdate(selectedField.name, instanceIndex, { value: url, label }); // Update parent state with the URL
    }
  };

  const handleCancelField = () => {
    setFieldValue('');
    setLabel('');
    setFile(null);
    setFileUrl('');
    setValidationError('');
    setIsValidated(false);
    onCancel(selectedField.name, instanceIndex);
  };

  const handleFileClick = () => {
    document.getElementById(`file-upload-input-${instanceIndex}`).click();
  };

  const getIconForField = (fieldName) => {
    switch (fieldName) {
      case 'email':
        return <AiOutlineMail className="inline-block mr-2 text-xs" />;
      case 'phone':
        return <AiOutlinePhone className="inline-block mr-2 text-xs" />;
      case 'linkedin':
        return <FaLinkedin className="inline-block mr-2 text-xs" />;
      case 'website':
        return <AiOutlineGlobal className="inline-block mr-2 text-xs" />;
      case 'facebook':
        return <AiOutlineFacebook className="inline-block mr-2 text-xs" />;
      case 'instagram':
        return <AiOutlineInstagram className="inline-block mr-2 text-xs" />;
      case 'snapchat':
        return <FaSnapchatGhost className="inline-block mr-2 text-xs" />;
      case 'pinterest':
        return <FaPinterestSquare className="inline-block mr-2 text-xs" />;
      case 'telegram':
        return <FaTelegram className="inline-block mr-2 text-xs" />;
      case 'tiktok':
        return <FaTiktok className="inline-block mr-2 text-xs" />;
      case 'whatsapp':
        return <FaWhatsapp className="inline-block mr-2 text-xs" />;
      case 'youtube':
        return <FaYoutube className="inline-block mr-2 text-xs" />;
      case 'link':
        return <FaLink className="inline-block mr-2 text-xs" />;
      case 'address':
        return <FaMapMarkerAlt className="inline-block mr-2 text-xs" />;
      case 'pdf':
        return <FaFilePdf className="inline-block mr-2 text-xs" /> 
      default:
        return null;
    }
  };

  const getPlaceholderForField = (fieldName) => {
    switch (fieldName) {
      case 'linkedin':
        return "https://www.linkedin.com/in/username";
      case 'website':
        return "https://www.example.com";
      case 'facebook':
        return "https://www.facebook.com/username";
      case 'instagram':
        return "https://www.instagram.com/username";
      case 'snapchat':
        return "https://www.snapchat.com/add/username";
      case 'pinterest':
        return "https://www.pinterest.com/username";
      case 'telegram':
        return "https://t.me/username";
      case 'tiktok':
        return "https://www.tiktok.com/@username";
      case 'whatsapp':
        return "+2126987534";
      case 'youtube':
        return "https://www.youtube.com/channel/username";
      case 'link':
        return "https://www.example.com";
      case 'address':
        return "123 Main St, City, Country";
      default:
        return "";
    }
  };

  if (!selectedField) {
    return <div className="w-2/4 p-4">Veuillez sélectionner un champ dans la liste</div>;
  }

  return (
    <div>
      {selectedField && (
        <div className="border border-gray-300 rounded-md p-4 mb-4 bg-white relative">
          <button
            className="absolute top-0 right-0 px-2 py-1 text-gray-600"
            onClick={handleCancelField}
            aria-label="Annuler"
          >
            X
          </button>
          <div className="mb-4 flex items-center">
            {!fieldValue && <div className="mr-2">{getIconForField(selectedField.name)}</div>}
            <label className="text-sm font-medium mb-2 block" htmlFor={selectedField.name}>
              {selectedField.label}
            </label>
          </div>

          {selectedField.name === 'pdf' ? (
            <div className="mb-4 flex flex-col sm:flex-row items-center">
            <div
              className="mb-2 sm:mb-0 sm:mr-4 p-2 border border-gray-300 rounded-md w-full sm:w-3/4 cursor-not-allowed select-none "
              style={{ cursor: 'not-allowed' }}
            >
              {localFileUrl ? (
                <p className="text-gray-400 truncate">{localFileUrl}</p>
              ) : (
                <span>Aucun fichier sélectionné</span>
              )}
            </div>
            <button
              type="button"
              onClick={handleFileClick}
              className="w-full sm:w-auto flex items-center border border-gray-300 rounded-md px-3 py-2 text-white bg-violet-600 hover:bg-violet-700"
            >
              <FaCloudUploadAlt className="mr-2" />
            </button>
            <input
              type="file"
              id={`file-upload-input-${instanceIndex}`}
              accept=".pdf"
              name={`file-upload-${instanceIndex}`}
              onChange={handleChange}
              className="hidden"
            />
          </div>
          ) : (
            <div className="mb-4">
              <input
                type="text"
                value={fieldValue}
                className={`appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full ${validationError && isValidated && 'border-red-500'}`}
                id={selectedField.name}
                name={selectedField.name}
                onChange={handleChange}
                placeholder={getPlaceholderForField(selectedField.name)}
                required
                onBlur={handleBlur}
              />
              {validationError && isValidated && (
                <p className="text-red-500 text-xs mt-1">{validationError}</p>
              )} 
            </div>
          )}

          <div className="mb-4">
            {selectedField.name === 'email' || selectedField.name === 'phone' || selectedField.name === 'address' ? (
              <div className="relative flex items-center">
                <select
                  value={label}
                  id={`${selectedField.name}-label`}
                  name={`${selectedField.name}-label`}
                  className="appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full pr-10"
                  onChange={handleChange}
                >
                  <option value="">Sélectionnez un libellé</option>
                  {labelsOptions[selectedField.name]?.map((labelOption) => (
                    <option key={labelOption} value={labelOption.toLowerCase()}>
                      {labelOption}
                    </option>
                  ))}
                </select>
                <div className="absolute right-0 top-0 bottom-0 flex items-center pr-3">
                  <CiCircleList />
                </div>
              </div>
            ) : (
              <div>
                <input
                  type="text"
                  value={label}
                  id={`${selectedField.name}-label`}
                  name={`${selectedField.name}-label`}
                  className={`appearance-none rounded-md border ${!isLabelValid && isLabelTouched ? 'border-red-500' : 'border-gray-300'} px-3 py-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full`}                
                  onChange={(e) => {
                    setLabel(e.target.value);
                    handleChange(e);
                  }}
                  onBlur={handleBlur} 
                  placeholder="Texte affiché"
                  required
                />
                {!isLabelValid && isLabelTouched && (
                  <p className="text-red-500 text-xs mt-1">Ce champ est requis</p>
                )} 
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

FieldsFormEdit.propTypes = {
  selectedField: PropTypes.object,
  instanceIndex: PropTypes.number.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  fileUrl: PropTypes.string,
  setFileUrl: PropTypes.func,
};

export default FieldsFormEdit;
