import React from 'react';

const Questions = () => {
    return (

        
        <div className="flex-grow flex flex-col items-center justify-center bg-gradient-to-r from-blue-500 to-purple-500 py-12 px-4 sm:px-6 lg:px-8 transform transition-all duration-700 hover:scale-105 hover:bg-gradient-to-l  ">
            <h1 className="text-2xl font-bold mb-4">Prenez contact avec notre équipe commerciale</h1>
            <h2>Vous avez une question générale sur notre produit, nos plans ou autre chose ?</h2><br />
            <form method="POST">
                <input type="text" name="name" placeholder="Name" required className="w-full mb-2 p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" />
                <input type="email" name="email" placeholder="Email" required className="w-full mb-2 p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" />
                <input type="text" name="subject" placeholder="Subject" required className="w-full mb-2 p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" />
                <textarea name="message" placeholder="Message" required className="w-full mb-2 p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"></textarea>
                <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-md shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">Send</button>
            </form>
        </div>
        
    );
}

export default Questions;
