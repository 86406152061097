import React, { useState , useEffect} from 'react';
import BackImg from "../../../images/BackImg.png"
import {Link} from "react-router-dom"

const Payment = () => {
  const [selectedOption, setSelectedOption] = useState('bank_transfer');
  const [showLoginOptions, setShowLoginOptions] = useState(true);



  useEffect(() => {
    const Option = localStorage.getItem('Option');
    if (Option) {
      setSelectedOption(JSON.parse(Option));
    }
  
  }, []);

  useEffect(() => {
    if (selectedOption) {
      localStorage.setItem('Option', JSON.stringify(selectedOption));
    }
  }, [selectedOption]);

  
  

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSkipLogin = () => {
    setShowLoginOptions(false);
  };

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const loggedIn = localStorage.getItem('isLoggedIn') === 'true';
    setIsLoggedIn(loggedIn);
  }, []);


  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-blue-500 to-purple-500 p-4">
      <div className="flex justify-end mr-4 sm:mr-96 -ml-96">
        <Link to='/'>
          <img src={BackImg} className="w-12 h-12 sm:w-16 sm:h-16" alt="Icon" />
        </Link>
      </div>

      <div className="w-full max-w-md bg-white shadow-lg rounded-lg p-8 border border-gray-300 mx-4 my-4">
        <h2 className="text-2xl font-bold mb-6 text-center">Paiement</h2>
        <form>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold mb-2">
              Choisir un mode de paiement
            </label>
            <div className="flex items-center mb-2">
              <input
                type="radio"
                id="card"
                name="paymentMethod"
                value="card"
                checked={selectedOption === 'card'}
                onChange={handleOptionChange}
                className="mr-2"
              />
              <label htmlFor="card" className="flex items-center cursor-pointer">
                <span>Payer par carte</span>
                <img src="https://img.icons8.com/?size=100&id=11079&format=png&color=1A1A1A" alt="Visa" className="ml-2 h-6" />
                <img src="https://img.icons8.com/?size=100&id=pNfxhf2SghvK&format=png&color=000000" alt="MasterCard" className="ml-2 h-6" />
                <img src="https://img.icons8.com/?size=100&id=SZ8HYUgmLcNc&format=png&color=000000" alt="Apple Pay" className="ml-2 h-6" />
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                id="bank_transfer"
                name="paymentMethod"
                value="bank_transfer"
                checked={selectedOption === 'bank_transfer'}
                onChange={handleOptionChange}
                className="mr-2"
              />
              <label htmlFor="bank_transfer" className="cursor-pointer">
                <span>Payer par virement bancaire</span>
              </label>
            </div>
          </div>
          {selectedOption === 'bank_transfer' && (
            <div className="text-gray-700 text-sm mt-4">
              Il vous faudra transférer le montant de la facture sur notre compte bancaire. Vous
              recevrez votre confirmation de commande par e-mail, comprenant nos coordonnées
              bancaires et le numéro de commande. Les biens seront mis de côté 2 jours pour vous et
              nous traiterons votre commande dès la réception du paiement.
            </div>
          )}
          {showLoginOptions && (
            <div className="flex flex-col items-center mt-6">
              {!isLoggedIn ? (
                <div className="flex flex-col items-center mt-6 w-full">
                  <Link to="/Register" className="w-full">
                    <button
                      type="submit"
                      className="bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg hover:bg-blue-700 w-full mb-4"
                    >
                      Se connecter
                    </button>
                  </Link>
                 
                </div>
              ) : (
                <Link to="/ConfirmPlan" className="w-full mt-6">
                  <button
                    type="submit"
                    className="bg-purple-600 text-white font-semibold py-2 px-4 rounded-lg hover:bg-purple-700 w-full"
                  >
                    Confirmer
                  </button>
                </Link>
              )}
            </div>
          )}
          {!showLoginOptions && (
            <Link to="/ConfirmPlan">
              <button
                type="submit"
                className="mt-6 w-full bg-purple-600 text-white font-semibold py-2 rounded-lg hover:bg-purple-700"
              >
                Continuer
              </button>
            </Link>
          )}
        </form>
      </div>
    </div>
  );
};


export default Payment;
