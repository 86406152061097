import { useState, useEffect } from 'react';
import useAllOrdersData from './useAllOrdersData';

const useLastOrderId = () => {
  const [lastOrderId, setLastOrderId] = useState(null);
  const orders = useAllOrdersData();

  useEffect(() => {
    if (orders.length > 0) {
      // Trier les ordres par `createdAt` de manière décroissante
      const sortedOrders = orders.sort((a, b) => 
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );

      // Récupérer l'ID du dernier ordre
      const lastOrder = sortedOrders[0];
      setLastOrderId(lastOrder.id);
    }
  }, [orders]);

  return lastOrderId;
};

export default useLastOrderId;
