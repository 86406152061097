import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const WaitPage = () => {
  const [userInfo, setUserInfo] = useState({
    userEmail: '',
    userCount: 0,
    plan: null,
    Option: '',
  });

  useEffect(() => {
    const userEmail = localStorage.getItem('userEmail');
    const userCount = localStorage.getItem('userCount');
    const plan = JSON.parse(localStorage.getItem('plan'));
    const Option = localStorage.getItem('Option');

    setUserInfo({
      userEmail,
      userCount,
      plan,
      Option,
    });
  }, []);

  return (
    <div className="min-h-screen flex flex-col bg-white m-20">
      <div className="flex flex-col md:flex-row items-center justify-center bg-white shadow-lg rounded-lg p-8 mx-4 my-8">
        {/* Left Section: Order Summary */}
        <div className="w-full md:w-2/3 md:mr-4 mb-4 md:mb-0">
          <h2 className="text-3xl font-bold text-green-600 mb-4">
            Félicitations, votre commande a été bien reçue ✅
          </h2>
          <div className="border rounded-lg p-6 mb-6 max-w-full md:max-w-2xl">
            <h3 className="text-lg font-bold mb-2">Récapitulatif de la commande :</h3>
            <p className="mb-2"><strong>Email :</strong> {userInfo.userEmail}</p>
            <p className="mb-2"><strong>Option :</strong> {userInfo.Option}</p>
            <p className="mb-2"><strong>User Count :</strong> {userInfo.userCount}</p>
            {userInfo.plan && (
              <div>
                <p className="mb-2"><strong>Plan Name :</strong> {userInfo.plan.name}</p>
                <p className="mb-2"><strong>Plan Price :</strong> {userInfo.plan.price} Dhs</p>
                <p className="mb-2"><strong>Plan Description :</strong> {userInfo.plan.description}</p>
              </div>
            )}
          </div>
          <p className="text-gray-700 mb-6">Vous souhaitez nous contacter avant? Appelez-nous au <strong>0660114484</strong></p>
          <Link to="/">
            <button className="bg-purple-600 text-white font-semibold py-3 px-6 rounded-lg hover:bg-purple-700 transition">
             accueil
            </button>
          </Link>
        </div>
        {/* Right Section: Image */}
        <div className="w-full md:w-1/3 md:ml-4">
          <img
            className="w-full rounded-lg shadow-lg"
            src="https://i.pinimg.com/564x/85/3d/ea/853dea5a4914a673df4120c416e88e88.jpg"
            alt="Confirmation"
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-around items-center bg-gray-900 p-8 text-white">
        <div className="w-full md:w-1/3 p-4">
          <iframe className="w-full h-56 md:h-64" src="https://www.youtube.com/embed/video1" title="Video 1" allowFullScreen></iframe>
          <p className="text-center mt-2">Aurore Weber</p>
        </div>
        <div className="w-full md:w-1/3 p-4">
          <iframe className="w-full h-56 md:h-64" src="https://www.youtube.com/embed/video2" title="Video 2" allowFullScreen></iframe>
          <p className="text-center mt-2">Faical Tadlaoui</p>
        </div>
      </div>
      <div className="bg-gray-900 text-white text-center p-4">
        <p>En attendant votre commande, suivez-nous sur :</p>
        <div className="flex justify-center mt-2">
          <a href="#" className="mx-2">Facebook</a>
          <a href="#" className="mx-2">Twitter</a>
          <a href="#" className="mx-2">Instagram</a>
        </div>
      </div>
      <footer className="bg-black text-white text-center p-4">
        <p>ElliteCard</p>
        <p>Legal | Commander</p>
      </footer>
    </div>
  );
};

export default WaitPage;
