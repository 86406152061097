import React, { useState, useEffect } from 'react';
import { firestore, doc, getDoc, updateDoc } from '../../../firebase';
import { auth } from '../../../firebase'; 
import BackImg from "../../../images/BackImg.png"
import {Link} from "react-router-dom"

const Profile = () => {
  const [user, setUser] = useState({
    email: '',
    name: 'Indéfini',
    phone: 'Indéfini',
    image: 'https://th.bing.com/th/id/OIP.cxcqa9RvhGnRd8x3N2oBdgHaHn?rs=1&pid=ImgDetMain'
  });
  const [editing, setEditing] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [image, setImage] = useState('');
  const [newImage, setNewImage] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const storedUser = localStorage.getItem('userProfile');
      if (storedUser) {
        const userData = JSON.parse(storedUser);
        setUser(userData);
        setName(userData.name || 'Indéfini');
        setPhone(userData.phone || 'Indéfini');
        setImage(userData.image || 'https://th.bing.com/th/id/OIP.cxcqa9RvhGnRd8x3N2oBdgHaHn?rs=1&pid=ImgDetMain');
      } else if (auth.currentUser) {
        const userDocRef = doc(firestore, 'profiles', auth.currentUser.uid);
        const userSnapshot = await getDoc(userDocRef);
        
        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          setUser(userData);
          setName(userData.name || 'Indéfini');
          setPhone(userData.phone || 'Indéfini');
          setImage(userData.image || 'https://th.bing.com/th/id/OIP.cxcqa9RvhGnRd8x3N2oBdgHaHn?rs=1&pid=ImgDetMain');
          localStorage.setItem('userProfile', JSON.stringify(userData));
        } else {
          console.log('Document not found!');
        }
      }
    };

    fetchUserData();
  }, []);

  const handleSave = async () => {
    let updatedUser = {
      ...user,
      name: name || user.name,
      phone: phone || user.phone,
      image: newImage || user.image || 'https://th.bing.com/th/id/OIP.cxcqa9RvhGnRd8x3N2oBdgHaHn?rs=1&pid=ImgDetMain',
    
    };
  
    try {
      const userDocRef = doc(firestore, 'profiles', auth.currentUser.uid);
      await updateDoc(userDocRef, updatedUser);
      console.log('Document updated with ID: ', auth.currentUser.uid);
      setUser(updatedUser);
      localStorage.setItem('userProfile', JSON.stringify(updatedUser));
    } catch (error) {
      console.error('Error updating document: ', error);
      return;
    }
  
    setEditing(false);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className=" min-h-screen        flex-grow flex flex-col items-center justify-center bg-gradient-to-r from-blue-500 to-purple-500 py-12 px-4 sm:px-6 lg:px-8 border ">
      <div className="max-w-2xl mx-auto bg-white shadow-lg rounded-xl overflow-hidden">
      <icon className="w-13 h-13 mb-96 ml-8 ">
       <Link to='/'><img src={BackImg} className="w-12 h-12" alt="Icon" /></Link> 
    </icon>
        <div className="px-8 py-6">
          <div className="flex items-center justify-center mb-6">
            <label className="cursor-pointer">
              <input
                type="file"
                accept="image/*"
                className="hidden"
                onChange={handleImageChange}
              />
              <img
                className="h-40 w-40 rounded-full object-cover border-4 border-white hover:border-indigo-800 transition duration-300"
                src={newImage || image || 'https://th.bing.com/th/id/OIP.cxcqa9RvhGnRd8x3N2oBdgHaHn?rs=1&pid=ImgDetMain'}
                alt="User Avatar"
              />
            </label>
          </div>
          <div className="text-center mt-4">
            <h1 className="text-3xl font-semibold text-gray-900">{user.email}</h1>
          </div>
          <div className="mt-6">
            <div className="flex items-center justify-between mb-6">
              <span className="text-lg font-semibold text-gray-700">Nom:</span>
              {editing ? (
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="px-4 py-2 border rounded-md focus:outline-none focus:border-purple-600"
                />
              ) : (
                <span className="text-lg text-gray-900">{user.name || 'Indéfini'}</span>
              )}
            </div>
            <div className="flex items-center justify-between mb-6">
              <span className="text-lg font-semibold text-gray-700">Email:</span>
              <span className="text-lg text-gray-900">{user.email}</span>
            </div>
            <div className="flex items-center justify-between mb-6">
              <span className="text-lg font-semibold text-gray-700">Téléphone:</span>
              {editing ? (
                <input
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="px-4 py-2 border rounded-md focus:outline-none focus:border-purple-600"
                />
              ) : (
                <span className="text-lg text-gray-900">{user.phone || 'Indéfini'}</span>
              )}
            </div>
            <div className="flex justify-end">
              {editing ? (
                <div className="flex space-x-3">
                  <button
                    onClick={handleSave}
                    className="bg-indigo-600 text-white px-6 py-2 rounded-md hover:bg-indigo-800 transition-all duration-300"
                  >
                    Sauvegarder
                  </button>
                  <button
                    onClick={() => setEditing(false)}
                    className="bg-gray-500 text-white px-6 py-2 rounded-md hover:bg-gray-600 transition-all duration-300"
                  >
                    Annuler
                  </button>
                </div>
              ) : (
                <button
                  onClick={() => setEditing(true)}
                  className="bg-green-500 text-white px-6 py-2 rounded-md hover:bg-green-600 transition-all duration-300"
                >
                  Modifier
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
