// data.js

// Card data for features
const cardData = [
    { text: 'Partage illimité', icon: '🔗' },
    { text: 'Nom, prénom et intitulé de poste imprimés sur la carte', icon: '🖊️' },
    { text: 'Modifications de vos informations à tout moment', icon: '✏️' },
    { text: 'Collecte de contact illimitée', icon: '👥', comingSoon: true },
    { text: 'QR code imprimé sur le dos de la carte', icon: '🔍' },
    { text: 'Statistiques détaillées', icon: '📊', comingSoon: true }
  ];
  
  // Pricing details for different card options
  const pricingDetails = [
    {
      nbr:"1",
      title: '1 Carte',
      oldPrice: '299',
      newPrice: '249',
      endDate: '30/9/2024',
      buttonText: 'Recevoir mon EliteCard',
      buttonLink: '/ConfirmPlan',
      icon: '💳',
    },
    {
      nbr:"10",
      title: '+10 Cartes',
      oldPrice: '229',
      newPrice: '199',
      endDate: '30/9/2024',
      buttonText: 'Recevoir mon EliteCard',
      buttonLink: '/ConfirmPlan',
      icon: '💳',
    },
    {
      nbr:"50",
      title: '+50 Cartes',
      buttonText: 'Rappeler moi',
      buttonLink: '/ContactForm',
      icon: '📞'
    }
  ];
  
  export { cardData, pricingDetails };
  