import axios from 'axios';

const pri_key = process.env.YOUCAN_PAY_PRI_KEY;
const pub_key = process.env.YOUCAN_PAY_PUB_KEY;
const currency = process.env.YOUCAN_PAY_CURRENCY;
const url = process.env.YOUCAN_PAY_API_URL;


console.log('API URL:', url);
console.log('Currency:', currency);
console.log('Private Key:', pri_key);
console.log('Public Key:', pub_key);

const commonParams = {
  headers: {
    Accept: 'application/json',
  },
};

const formData = (obj) => {
  const form = new FormData();
  Object.entries(obj).forEach(([key, value]) => {
    form.append(key, value);
  });
  return form;
};

const tokenize = async ({ amount, demandeId }) => {
  try {
    const response = await axios.post(`${url}/tokenize`, formData({
      amount: amount * 100,
      currency,
      order_id: demandeId,
      pri_key,
    }), commonParams);

    return response.data;
  } catch (error) {
    const message = error.response?.data?.message || 'Erreur lors de la tokenisation';
    throw new Error(message);
  }
};

export const payWithCard = async (tokenizeParams, card) => {
  try {
    const { token } = await tokenize(tokenizeParams);

    const response = await axios.post(`${url}/pay`, formData({
      pub_key,
      token_id: token.id,
      credit_card: card.creditCard,
      expire_date: card.expireDate,
      cvv: String(card.cvv),
      card_holder_name: card.cardHolderName,
    }), commonParams);

    return response.data;
  } catch (error) {
    const message = error.response?.data?.message || 'Erreur lors du paiement';
    throw new Error(message);
  }
};
