import React from 'react';


const Analytics = () => {
  return (
    <div className="p-4">
      <h1>Statistiques</h1>

    </div>
  );

  
};

export default Analytics;
