import React, { useState, useEffect } from 'react';
import { auth, firestore, storage } from '../../../firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadString, getDownloadURL } from 'firebase/storage'; 

const Settings = () => {
  const [user, setUser] = useState({
    email: '',
    name: 'Indéfini',
    image: 'https://th.bing.com/th/id/OIP.cxcqa9RvhGnRd8x3N2oBdgHaHn?rs=1&pid=ImgDetMain'
  });
  const [editing, setEditing] = useState(false);
  const [name, setName] = useState('');
  const [newImage, setNewImage] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(firestore, 'profilesTest', user.uid);
        const userSnapshot = await getDoc(userDocRef);

        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          setUser({
            ...userData,
            email: user.email // Assurez-vous que l'email est correctement affiché
          });
          setName(userData.name || 'Indéfini');
          setNewImage(userData.image || 'https://th.bing.com/th/id/OIP.cxcqa9RvhGnRd8x3N2oBdgHaHn?rs=1&pid=ImgDetMain');
          localStorage.setItem('userProfile', JSON.stringify(userData));
        } else {
          console.log('Document not found!');
        }
      }
    };

    fetchUserData();
  }, []);

  const handleSave = async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('Aucun utilisateur n\'est connecté');
      }

      // Créer un objet de mise à jour propre
      const updatedUser = {
        name: name || user.displayName,
        image: newImage || user.photoURL || 'https://th.bing.com/th/id/OIP.cxcqa9RvhGnRd8x3N2oBdgHaHn?rs=1&pid=ImgDetMain'
      };

      // Assurez-vous que updatedUser ne contient que des données valides
      const cleanedUserData = {
        ...updatedUser,
      };

      const userDocRef = doc(firestore, 'profiles', user.uid);
      await updateDoc(userDocRef, cleanedUserData);
      console.log('Document updated with ID: ', user.uid);

      // Mettre à jour l'état local
      setUser({
        ...user, // Garder les autres propriétés intactes
        ...cleanedUserData
      });

      localStorage.setItem('userProfile', JSON.stringify({
        ...user,
        ...cleanedUserData
      }));
    } catch (error) {
      console.error('Error updating document: ', error);
      alert(`Erreur : ${error.message}`);
    }

    setEditing(false);
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        try {
          // Enregistrez l'image dans Firebase Storage
          const imageRef = ref(storage, `images/${auth.currentUser.uid}/${file.name}`);
          await uploadString(imageRef, reader.result, 'data_url');

          // Obtenez l'URL de l'image
          const imageUrl = await getDownloadURL(imageRef);

          // Mettez à jour l'état avec l'URL de l'image
          setNewImage(imageUrl);

          // Mettre à jour l'image dans Firestore
          const user = auth.currentUser;
          if (user) {
            const userDocRef = doc(firestore, 'profiles', user.uid);
            await updateDoc(userDocRef, { image: imageUrl });
            localStorage.setItem('userProfile', JSON.stringify({ ...user, image: imageUrl }));
          }
        } catch (error) {
          console.error('Error uploading image: ', error);
          alert(`Erreur : ${error.message}`);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePasswordResetRequest = async () => {
    try {
      await sendPasswordResetEmail(auth, user.email);
      alert('Un e-mail de réinitialisation du mot de passe a été envoyé.');
    } catch (error) {
      console.error('Erreur lors de l\'envoi de l\'email de réinitialisation du mot de passe :', error.message);
      alert(`Erreur : ${error.message}`);
    }
  };

  return (
    <div className="flex items-center min-h-screen flex-col">
      <h1 className='font-bold text-violet-600 mb-6 mt-8 font-mono'>Compte</h1>
      <div className="max-w-md mx-auto bg-white shadow-md rounded-lg overflow-hidden">
        <div className="px-6 py-4">
          <div className="flex items-center justify-center mb-4">
            <label className="cursor-pointer">
              <input
                type="file"
                accept="image/*"
                className="hidden"
                onChange={handleImageChange}
              />
              <img
                className="h-32 w-32 rounded-full object-cover border-4 border-white hover:border-purple-600 transition duration-300"
                src={newImage || user.image || 'https://th.bing.com/th/id/OIP.cxcqa9RvhGnRd8x3N2oBdgHaHn?rs=1&pid=ImgDetMain'}
                alt="User Avatar"
              />
            </label>
          </div>
          <div className="text-center mt-4">
            <h1 className="text-2xl font-semibold text-gray-800">{user.email}</h1>
          </div>
          <div className="mt-4">
            <div className="flex items-center justify-between mb-4">
              <span className="text-sm font-semibold text-gray-600">Nom:</span>
              {editing ? (
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="px-3 py-2 border rounded-md focus:outline-none focus:border-purple-600"
                />
              ) : (
                <span className="text-sm text-gray-800">{user.name || 'Indéfini'}</span>
              )}
            </div>
            <div className="flex items-center justify-between mb-4">
              <span className="text-sm font-semibold text-gray-600">Email:</span>
              <span className="text-sm text-gray-800">{user.email}</span>
            </div>
            <div className="flex items-center justify-center mt-4">
              {editing ? (
                <div className="flex space-x-1 mr-1">
                  <button
                    onClick={handleSave}
                    className="bg-purple-600 text-white px-4 py-2 rounded-md hover:bg-purple-700 transition-all duration-300"
                  >
                    Sauvegarder
                  </button>
                  <button
                    onClick={() => setEditing(false)}
                    className="bg-white text-gray-500 px-4 py-2 rounded-md hover:bg-gray-200 transition-all duration-300 border border-gray-300"
                  >
                    Annuler
                  </button>
                </div>
              ) : (
                <button
                  onClick={() => setEditing(true)}
                  className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition-all duration-300"
                >
                  Modifier
                </button>
              )}
            </div>
            <div className="mt-4 text-center">
              <button
                onClick={handlePasswordResetRequest}
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-all duration-300"
              >
                Mot de passe oublié ?
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
