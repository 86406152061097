import React from 'react';
import Questions from '../../Element/Questions';


const FeedBack = () => {
  return (
    <div>
      
    <div className="max-w-7xl mx-auto py-19 bg- shadow-lg rounded-lg p-6 transition duration-500 hover:shadow-xl mb-11 mt-4 md:mt-40  "> 
      <Questions/>
    </div>
    

    </div>
  );
};

export default FeedBack;
