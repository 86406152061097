import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Informations from './Informations';
import Fields from './Fields';
import { addDoc, collection, doc } from 'firebase/firestore';
import { db, storage, storageRef, uploadBytes } from '../../../firebase';
import useCurrentUser from '../../../hooks/useCurrentUser';
import BackImg from "../../../images/BackImg.png";
import GenericProfile from '../GenericProfile/GenericProfile';
import GenericCard from '../GenericProfile/GenericCard';

const Profile = ({onSave, onCancel, statut = false, orderId: propOrderId}) => {
  const currentUser = useCurrentUser();
  const { orderId: paramOrderId } = useParams();
  const navigate = useNavigate();
  const [fileUrl, setFileUrl] = useState('');
  const orderId = propOrderId || paramOrderId;

  const initialProfileData = {
    fields: [],
    profilePhotoUrl: '',
    fullName: '',
    title: '',
    departement: '',
    company: '',
    logoUrl: '',
    aboutMe: '',
    lastModified: null 
  };

  const [profileData, setProfileData] = useState(initialProfileData);
  const [activeSection, setActiveSection] = useState('informations');
  const [alert, setAlert] = useState(null);

  const handleUpdate = (data) => {
    setProfileData((prevData) => ({ ...prevData, ...data }));
  };

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  async function fetchBlob(url) {
    const response = await fetch(url);
    return await response.blob();
  }

  const handleConfirm = async () => {
  if (!profileData.fullName) {
    setAlert("Veuillez saisir un Nom complet.");
    return;
  }

  for (const field of profileData.fields) {
    for (const instance of field.instances) {
      if (!instance.value) {
        setAlert(`Veuillez remplir le champ ${field.label}.`);
        return;
      }
    }
  }

  try {
    if (currentUser) {
      // Référence au document utilisateur
      const userDocRef = doc(db, 'profilesTest', currentUser.uid);
      // Référence à la sous-collection profilesData dans le document utilisateur
      const profileDocRef = collection(userDocRef, 'profilesData');
      
      const docRef = await addDoc(profileDocRef, {
        ...profileData,
        lastModified: new Date().toISOString(),
        orderId: orderId,
        status: "En attente de paiement" 
      });
      console.log("Document écrit avec ID : ", docRef.id);
      
      if (profileData.profilePhotoUrl) {
        const profilePhotoRef = storageRef(storage, `images/${currentUser.uid}/${docRef.id}/photoProfile.png`);
        const photoBlob = await fetchBlob(profileData.profilePhotoUrl);
        await uploadBytes(profilePhotoRef, photoBlob);
      }
      
      if (profileData.logoUrl) {
        const logoRef = storageRef(storage, `images/${currentUser.uid}/${docRef.id}/logo.png`);
        const logoBlob = await fetchBlob(profileData.logoUrl);
        await uploadBytes(logoRef, logoBlob);
      }
      
      setProfileData(initialProfileData);
      setActiveSection('informations');
      
      if (statut) {
        onCancel();
      } else {
        navigate(`/thankyou/${orderId}/${docRef.id}`);
      }
    } else {
      console.error("Aucun utilisateur actuel trouvé");
    }
  } catch (e) {
    console.error("Erreur lors de l'ajout du document : ", e);
  }
};
  
  const handleCancel = () => {
    if(statut){
      onCancel();
    }else{
      console.log("Modifications du profil annulées");
      setProfileData(initialProfileData);
      setActiveSection('informations');
    }
  };

  return (
    <div className="flex flex-col md:flex-row items-start justify-start w-full bg-white p-4 sm:p-6 md:p-20 bg-gradient-to-r from-blue-500 to-purple-500 py-12 px-4 sm:px-6 lg:px-8 border">
      {/* Mobile View */}
      <div className="md:hidden w-full max-w-full">
        <button onClick={handleCancel} className=''>
          <img src={BackImg} className="w-12 h-12 mb-3" alt="Icon" />
        </button>
        <h1 className="text-lg sm:text-xl font-bold mb-4">Mon Profil</h1>
        <div className='flex items-center space-x-4 sm:space-x-6 mb-4'>
          <div
            className={`cursor-pointer ${activeSection === 'informations' ? 'border-b-2 border-white text-white' : 'border-b-2 border-transparent '}`}
            onClick={() => handleSectionChange('informations')}
          >
            Informations
          </div>
          <div
            className={`cursor-pointer animate-bounce ${activeSection === 'fields' ?  'border-b-2 border-white text-white' : 'border-b-2 border-transparent '}`}
            onClick={() => handleSectionChange('fields')}
          >
            Champs ✨
          </div>
        </div>
        <div className="mt-4">
          {activeSection === 'informations' ? (
            <Informations profileData={profileData} onUpdate={handleUpdate} />
          ) : (
            <Fields fields={profileData.fields} onUpdate={handleUpdate} orderId={orderId} currentUserId={currentUser.uid} fileUrl={fileUrl} setFileUrl={setFileUrl}/>
          )}
        </div>
        {alert && (
          <div className="mt-4 p-3 border border-red-500 text-red-500 bg-red-100 rounded">
            {alert}
          </div>
        )}
        <div className="mt-4 flex flex-col space-y-2">
          <button className="bg-white border border-gray-500 text-gray-500 px-4 py-2 rounded transition-all duration-300 ease-in-out hover:bg-white-200 hover:text-gray-400 hover:border-blue-600 transform hover:scale-105" onClick={handleCancel}>
            Annuler
          </button>
          <button className="bg-purple-600 text-white px-4 py-2 rounded transition-all duration-300 ease-in-out hover:bg-white-200 hover:text-gray-100 hover:border-blue-600 transform hover:scale-105" onClick={handleConfirm}>
            Confirmer
          </button>
        </div>
        {/* Adding the profile and card sections for mobile view */}
        <div className="mt-6">
          <GenericCard profileData={profileData} onUpdate={handleUpdate}  width = {320} height={200}/>
        </div>
        <div className="mt-6">
          <GenericProfile profileData={profileData} onUpdate={handleUpdate} width={370} height={200} radius={60}/>
        </div>
      </div>

      {/* Desktop View */}
      <div className="hidden md:flex flex-row w-full">
        <div className="flex flex-col w-2/3 pr-10">
          <div className="flex items-center mb-4"> 
            <button onClick={handleCancel} className=''>
              <img src={BackImg} className="w-12 h-12 mr-3" alt="Icon" /> 
            </button>
            <h1 className="text-xl font-bold">Mon Profil</h1>
          </div>

          <div className='flex items-center space-x-6 mb-4'>
            <div
              className={`cursor-pointer pb-2 ${activeSection === 'informations' ? 'border-b-2 border-white text-white' : 'border-b-2 border-transparent'}`}
              onClick={() => handleSectionChange('informations')}
            >
              Informations
            </div>
            <div
              className={`cursor-pointer pb-2 flex items-center animate-bounce ${activeSection === 'fields' ? 'border-b-2 border-white text-white' : 'border-b-2 border-transparent'}`}
              onClick={() => handleSectionChange('fields')}
            >
              Champs
              <span className={`ml-2`}>✨</span>
            </div>
          </div>

          <div className="">
            {activeSection === 'informations' ? (
              <Informations profileData={profileData} onUpdate={handleUpdate} />
            ) : (
              <Fields fields={profileData.fields} onUpdate={handleUpdate} orderId={orderId} currentUserId={currentUser.uid} fileUrl={fileUrl} setFileUrl={setFileUrl}/>
            )}
          </div>
          {alert && (
            <div className="mt-4 p-3 border border-red-500 text-red-500 bg-red-100 rounded">
              {alert}
            </div>
          )}
          <div className="mt-4">
            <button className="bg-white border border-gray-500 text-gray-500 px-4 py-2 rounded transition-all duration-300 ease-in-out hover:bg-white-200 hover:text-gray-400 hover:border-blue-600 transform hover:scale-105 ml-2" onClick={handleCancel}>
              Annuler
            </button>
            <button className="bg-purple-600 text-white px-4 py-2 rounded transition-all duration-300 ease-in-out hover:bg-white-200 hover:text-gray-100 hover:border-blue-600 transform hover:scale-105 ml-2" onClick={handleConfirm}>
              Confirmer
            </button>
          </div>
        </div>

        <div className="flex flex-col w-1/3 space-y-6">
          <GenericCard profileData={profileData} onUpdate={handleUpdate} />
          <div className='w-full'>
            <GenericProfile profileData={profileData} onUpdate={handleUpdate}/>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Profile;
