import React, { useEffect, useState } from 'react';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { auth } from '../../firebase'; 
import { onAuthStateChanged, signOut } from 'firebase/auth';
import logo from "../../images/logo.png";
import defaultProfileImg from "../../images/fake-profile-img.jpg"; 

const navigation = [
  { name: 'Acceuil', href: 'acceuil', current: true },
  { name: 'Avantages', href: 'avantage', current: false },
  { name: 'Fonctionnalites', href: 'fonct', current: false },
  { name: 'Notre offre', href: 'offre', current: false },
  { name: 'Questions', href: 'Questions', current: false },
 
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function CustomNavbar() {
  const [user, setUser] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false); 
  const [profileImage, setProfileImage] = useState('');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      const storedProfile = localStorage.getItem('userProfile');
      if (storedProfile) {
        const profileData = JSON.parse(storedProfile);
        setProfileImage(profileData.image || defaultProfileImg);
      } else {
        setProfileImage(defaultProfileImg);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setUser(null);
      clearLocalStorage();
      localStorage.setItem('isLoggedIn', 'false');
      window.location.href = '/'; 
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const clearLocalStorage = () => {
    localStorage.clear(); 
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleProfileClick = () => {
    if (!user) {
      window.location.href = '/Register'; 
    }
  };

  return (
    <Disclosure as="nav" className="bg-gray-800 fixed top-0 left-0 right-0 z-50 p-2">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
            {/* Mobile menu button*/}
            <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white mr-4">
              <span className="absolute -inset-0.5" />
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden" />
              <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block" />
            </DisclosureButton>
          </div>
          <div className="flex items-center ">
            <RouterLink to="/">
              <span className="flex items-center">
                <img
                  src={logo}
                  className="h-20 rounded-full cursor-pointer"
                  alt="EliteCard Logo"
                />
              </span>
            </RouterLink>
          </div>
          <div className="hidden sm:flex sm:ml-6 relative">
            <div className="flex space-x-4">
              {navigation.map((item) => (
                <ScrollLink
                  to={item.href}
                  smooth={true}
                  duration={500}
                  key={item.name}
                  className={classNames(
                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'rounded-md px-3 py-2 text-sm font-medium cursor-pointer'
                  )}
                >
                  {item.name}
                </ScrollLink>
              ))}
              {user ? (
                <div className="relative">
                  <div className="flex items-center space-x-2 cursor-pointer" onClick={toggleDropdown}>
                    <span className="text-gray-300 rounded-md px-3 py-2 text-sm font-medium">
                      {user.displayName?.split(' ')[0] || user.email?.split('@')[0]}
                    </span>
                    <img
                      src={profileImage}
                      alt="Profile"
                      className="h-8 w-8 rounded-full"
                    />
                  </div>
                  {dropdownOpen && (
                    <div
                      className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20 transition-opacity duration-300 ease-in-out transform origin-top-right scale-95"
                      onMouseLeave={toggleDropdown} 
                    >
                      <div className="py-1">
                        <RouterLink to={'/espaceClient'}>
                          <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors duration-200" onClick={handleProfileClick}>
                            Mon espace Client
                          </button>
                        </RouterLink>
                        <button
                          onClick={handleSignOut}
                          className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors duration-200"
                        >
                          Déconnecter
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <RouterLink to="/login">
                  <button
                    type="button"
                    className="bg-indigo-500 text-white rounded-md px-3 py-2 text-sm font-medium transition-transform hover:scale-105"
                  >
                    Se Connecter
                  </button>
                </RouterLink>
              )}
            </div>
          </div>
        </div>
      </div>

      <DisclosurePanel className="sm:hidden">
        <div className="space-y-1 px-2 pb-3 pt-2">
          {navigation.map((item) => (
            <ScrollLink
              to={item.href}
              smooth={true}
              duration={100}
              key={item.name}
              className={classNames(
                item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                'block rounded-md px-3 py-2 text-base font-medium cursor-pointer'
              )}
            >
              {item.name}
            </ScrollLink>
          ))}
          {user ? (
            <div className="space-y-1">
              <div className="flex items-center space-x-2 cursor-pointer" onClick={toggleDropdown}>
                <span className="text-gray-300 rounded-md px-3 py-2 text-base font-medium">
                  {user.displayName?.split(' ')[0] || user.email?.split('@')[0]}
                </span>
                <img
                  src={profileImage}
                  alt="Profile"
                  className="h-8 w-8 rounded-full"
                />
              </div>
              {dropdownOpen && (
                <div
                  className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20 transition-opacity duration-300 ease-in-out transform origin-top-right scale-95"
                  onMouseLeave={toggleDropdown} 
                >
                  <div className="py-1">
                    <button
                      onClick={handleSignOut}
                      className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors duration-200"
                    >
                      Déconnecter
                    </button>
                    <RouterLink to={'/espaceClient'}>
                      <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors duration-200" onClick={handleProfileClick}>
                      Mon espace Client
                      </button>
                    </RouterLink>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <RouterLink to="/login">
              <button
                type="button"
                className="bg-indigo-500 text-white rounded-md px-3 py-2 text-base font-medium transition-transform hover:scale-105"
              >
                Se Connecter
              </button>
            </RouterLink>
          )}
        </div>
      </DisclosurePanel>
    </Disclosure>
  );
}
