import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { db, addDoc, collection, auth } from '../../../firebase';
//import AdminEmailTemplate from '../../../utils/emails/AdminEmailTemplate ';
//import ReactDOMServer from 'react-dom/server';


const ConfirmPlan = () => {
  //console.log("REACT_APP_API_URL = ",process.env.REACT_APP_API_URL)
  var selectedCard = localStorage.getItem("selectedCard");

  const [step, setStep] = useState(1);
  const [userCount, setUserCount] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [deliveryAddress, setDeliveryAddress] = useState('');
  const [error, setError] = useState('');
  const user = auth.currentUser; 
  const navigate = useNavigate();
  const handleAddressChange = (e) => {
    setDeliveryAddress(e.target.value);
  };
  

  useEffect(() => {
    var storedUserCount = localStorage.getItem('selectedCard');

    if (storedUserCount === "1 Carte") {
        setUserCount(1);
    } else if (storedUserCount === "+10 Cartes") {
        setUserCount(10);
    } 

    console.log(storedUserCount);

    const storedPhoneNumber = localStorage.getItem('userPhone');
    if (storedPhoneNumber) {
      setPhoneNumber(storedPhoneNumber);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('userCount', userCount);
    calculateTotalPrice(userCount);
  }, [userCount]);

  useEffect(() => {
    localStorage.setItem('totalPrice', totalPrice);
    localStorage.setItem('discount', discount);
  }, [totalPrice, discount]);

  const calculateTotalPrice = (count) => {
    let price = 0;
    let originalPrice = 0;
    let discountAmount = 0;

    if (count >= 50) return;
    if (count >= 10) {
      originalPrice = count * 299;
      price = count * 199;
      discountAmount = originalPrice - price;
    } else {
      originalPrice = count * 350;
      price = count * 249;
      discountAmount = originalPrice - price;
    }

    setTotalPrice(price);
    setDiscount(discountAmount);
  };

  const incrementUserCount = () => setUserCount(userCount + 1);
  const decrementUserCount = () => userCount > 1 && setUserCount(userCount - 1);

  const handleConfirm = async () => {
    if (!deliveryAddress.trim()) {
      setError('L\'adresse de livraison est obligatoire.');
      return;
    }

    try {
      // Envoi des données de commande à Firestore
      const ordersRef = collection(db, 'orders'); // Reference the collection
      const orderDocRef = await addDoc(ordersRef, {
        userId: user ? user.uid : null,
        selectedCard,
        userCount,
        totalPrice,
        phoneNumber,
        deliveryAddress,
        status: "en attente de paiement",
        createdAt: new Date().toISOString()
      });
      console.log(`Order added to Firebase:${orderDocRef.id}`);
  
      // Vérifier si l'utilisateur est défini
      if (!user) {
        throw new Error('User is not defined');
      }
  
      /*const adminEmailContent = ReactDOMServer.renderToStaticMarkup(
        <AdminEmailTemplate
          userEmail={user.email}
          userCount={userCount}
          totalPrice={totalPrice}
          discount={discount}
          phoneNumber={phoneNumber}
          deliveryAddress={deliveryAddress}
        />
      );
  
      // Préparer les données d'envoi pour l'administrateur
      const adminEmailData = {
        email: process.env.REACT_APP_ADMIN_EMAIL,
        subject: "Nouvelle Commande Reçue",
        htmlContent: adminEmailContent
      };
  
      // Envoyer un email à l'administrateur
      //console.log('test send email url : ', `${process.env.REACT_APP_API_URL}/api/sendAdminEmail`);
      const adminResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/sendAdminEmail`, {
        method: 'POST',
        body: JSON.stringify(adminEmailData),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
  
      if (!adminResponse.ok) {
        const errorText = await adminResponse.text();
        throw new Error(`Failed to send admin email: ${errorText}`);
      }*/
      navigate(`/profileForm/${orderDocRef.id}`);
  
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleContinue = () => {
    if (userCount >= 50) {
      setStep(3);
    } else {
      setStep(2);
    }
  };

  const handleInputChange = (e) => {
    const value = Number(e.target.value);
    if (value > 0) setUserCount(value);
  };
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-blue-500 to-purple-500 p-4">
      <div className="flex justify-end mr-4 sm:mr-96 -ml-96"></div>
      <div className="bg-yellow-200 text-yellow-800 p-4 rounded-lg mb-6 shadow-lg">
        <p className="text-center font-bold text-lg">Prix:</p>
        <ul className="list-disc list-inside">
          <li><span className="line-through">350 Dhs</span> → 249 Dhs pour 1 carte</li>
          <li><span className="line-through">299 Dhs</span> → 199 Dhs pour 10+ cartes</li>
          <li>50+ cartes: Un agent EliteCard vous accompagne.</li>
        </ul>
      </div>
      {step === 1 && (
        <div className="w-full max-w-lg bg-white shadow-lg rounded-lg p-6">
          <h2 className="text-2xl font-bold mb-6 text-center">Nombre de cartes</h2>
          <p className="text-gray-500 text-center mb-6">Une tarification flexible qui évolue avec vous.</p>
          <div className="flex items-center justify-center mb-6">
            <button
              type="button"
              onClick={decrementUserCount}
              className="bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded-lg hover:bg-gray-400"
            >
              -
            </button>
            <input
              type="number"
              value={userCount}
              onChange={handleInputChange}
              className="mx-4 text-2xl w-16 text-center border border-gray-300 rounded-lg"
              min="1"
            />
            <button
              type="button"
              onClick={incrementUserCount}
              className="bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded-lg hover:bg-gray-400"
            >
              +
            </button>
          </div>
          {userCount < 50 && (
            <div className="text-center text-xl font-bold mb-4">
              <p>
                <span className="font-bold text-green-600">{totalPrice} Dhs  <span className="line-through text-gray-700 size-1">{userCount * (userCount >= 10 ? 299 : 350)} Dhs</span></span>
              </p>
            </div>
          )}
          <div className="flex justify-between mt-4">
            <Link to="/">
              <button
                type="button"
                onClick={() => setStep(1)}
                className="bg-gray-300 text-gray-700 font-semibold py-2 px-12 rounded-lg hover:bg-gray-400"
              >
                Annuler
              </button>
            </Link>
            <button
              type="button"
              onClick={handleContinue}
              className="bg-purple-600 text-white font-semibold py-2 px-12 rounded-lg hover:bg-purple-700"
            >
              Continuer
            </button>
          </div>
        </div>
      )}
      {step === 2 && (
        <div className="w-full max-w-lg bg-white shadow-lg rounded-lg p-6">
          <h2 className="text-2xl font-bold mb-6 text-center">Confirmer la commande</h2>
          <div className="text-center text-xl font-bold mb-4">
            <p>Total: <span className="font-bold text-purple-600">{totalPrice} Dhs</span></p>
            <p className="text-gray-500">
              Ancien prix: <span className="line-through text-gray-700">{userCount * (userCount >= 10 ? 299 : 350)} Dhs</span>
            </p>
          </div>
          <div className="mb-4">
            <label htmlFor="delivery-address" className="block text-gray-700 text-sm font-bold mb-2">Adresse de livraison:</label>
            <input
              id="delivery-address"
              type="text"
              placeholder="Saisissez votre adresse de livraison"
              value={deliveryAddress}
              onChange={handleAddressChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500"
            />
            {error && (
              <p className="text-red-500 text-sm mt-2">{error}</p>
            )}
          </div>
          <div className="flex justify-between mt-4">
            <button
              type="button"
              onClick={() => setStep(1)}
              className="bg-gray-300 text-gray-700 font-semibold py-2 px-12 rounded-lg hover:bg-gray-400"
            >
              Retour
            </button>
            
              <button
                type="button"
                onClick={handleConfirm}
                className="bg-purple-600 text-white font-semibold py-2 px-12 rounded-lg hover:bg-purple-700"
              >
                Confirmer
              </button>
            
          </div>
        </div>
      )}
      {step === 3 && (
        <div className="w-full max-w-lg bg-white shadow-lg rounded-lg p-6">
          <h2 className="text-2xl font-bold mb-6 text-center">Commander plus de 50 cartes</h2>
          <p className="text-gray-500 text-center mb-6">
            Merci de votre intérêt pour commander plus de 50 cartes. Veuillez remplir le formulaire de contact pour que nous puissions vous fournir une assistance personnalisée.
          </p>
          <Link to="/ContactForm">
            <button
              type="button"
              className="bg-purple-600 text-white font-semibold py-2 px-12 rounded-lg hover:bg-purple-700 w-full"
            >
              Aller au formulaire de contact
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default ConfirmPlan;
