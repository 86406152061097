import React from 'react';
import QRCode from 'qrcode.react';
import { getProfileUrl } from '../../../constants';
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    WhatsappIcon,
    EmailIcon,
} from 'react-share';
import { Helmet } from 'react-helmet';

const ShareModal = ({ isOpen, onClose, profileId, userId }) => {
    if (!isOpen) return null;

    const urlToShare = getProfileUrl(profileId);

    const copyLinkToClipboard = () => {
        const link = urlToShare;
        const input = document.createElement('input');
        input.style.position = 'fixed';
        input.style.opacity = 0;
        input.value = link;
        document.body.appendChild(input);
        input.select();
        input.setSelectionRange(0, 99999); /* Pour les mobiles */
        document.execCommand('copy');
        document.body.removeChild(input);
        alert('Lien copié dans le presse-papiers !');
    };

    return (
      <>
      <Helmet>
        <title>EliteCard</title>
        <meta name="description" content="Découvrez et partagez votre carte de visite numérique avec EliteCard. Simplifiez vos échanges professionnels avec une carte moderne et dynamique." />
        <meta name="keywords" content="business card, digital, seo" />
        <meta name="author" content="EliteCard" />
        <meta property="og:title" content="EliteCard.ma" />
        <meta property="og:description" content="Découvrez et partagez votre carte de visite numérique avec EliteCard. Simplifiez vos échanges professionnels avec une carte moderne et dynamique." />
        <meta property="og:image" content={'https://elitecard.ma/images/icon.png'} />
        <meta property="og:url" content={urlToShare} />
        <meta name="twitter:title" content="EliteCard.ma" />
        <meta name="twitter:description" content="Découvrez et partagez votre carte de visite numérique avec EliteCard. Simplifiez vos échanges professionnels avec une carte moderne et dynamique." />
        <meta name="twitter:image" content={'https://elitecard.ma/images/icon.png'} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-4 rounded-lg shadow-lg w-80">
                <h2 className="text-lg font-bold mb-2 text-center">Partager</h2>
                <QRCode value={urlToShare} size={128} className="mx-auto mb-4" />
                <div className="mt-4 text-center">
                    <p className='text-purple-600 mt-2'>Scanner Code QR ou Partager</p>
                    <div className="flex justify-center space-x-2 mt-4">
                        <FacebookShareButton url={urlToShare}>
                            <FacebookIcon size={32} round />
                        </FacebookShareButton>
                        <TwitterShareButton url={urlToShare}>
                            <TwitterIcon size={32} round />
                        </TwitterShareButton>
                        <LinkedinShareButton url={urlToShare}>
                            <LinkedinIcon size={32} round />
                        </LinkedinShareButton>
                        <WhatsappShareButton url={urlToShare}>
                            <WhatsappIcon size={32} round />
                        </WhatsappShareButton>
                        <EmailShareButton url={urlToShare}>
                            <EmailIcon size={32} round />
                        </EmailShareButton>
                    </div>
                    <button className="bg-white border border-violet-500 text-violet-500 px-4 py-2 rounded transition-all duration-300 ease-in-out hover:bg-violet-100 transform hover:scale-105 mt-5" onClick={copyLinkToClipboard}>
                        Copier Lien
                    </button>
                </div>
                <button className="absolute top-12 right-16 font-extrabold text-white" onClick={onClose}>X</button>
            </div>
        </div>
      </>
    );
};

export default ShareModal;
