import { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, query, getDocs, where } from 'firebase/firestore';
import useCurrentUser from './useCurrentUser';

const useAllOrdersData = () => {
  const [orders, setOrders] = useState([]);
  const currentUser = useCurrentUser();

  useEffect(() => {
    const fetchData = async () => {
      if (currentUser) {
        const userId = currentUser.uid;
    
        try {
          // Créez une référence à la collection 'orders'
          const ordersCollection = collection(db, 'orders');
    
          // Filtrez les documents où le champ 'userId' correspond à currentUser.uid
          const ordersQuery = query(ordersCollection, where('userId', '==', userId));
    
          // Récupérez les documents qui correspondent à la requête
          const snapshot = await getDocs(ordersQuery);
          const ordersData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
    
          setOrders(ordersData);
        } catch (error) {
          console.error('Error fetching orders:', error);
        }
      }
    };

    fetchData();
  }, [currentUser]);

  return orders;
};

export default useAllOrdersData;
