import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-500 to-purple-500">
      <div className="text-center">
        <h1 className="text-6xl font-bold text-white mb-4">404</h1>
        <p className="text-xl text-white mb-8">Page Not Found</p>
        <p className="text-lg text-white mb-4">
          Désolé, la page que vous recherchez n'existe pas.
        </p>
        <br />
        <Link to="/" className="bg-white text-red-500 px-6 py-3 rounded-lg shadow-lg hover:bg-gray-100 transition">
          Retour à l'accueil
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
