import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from '../../Element/Sidebar';
import Profiles from './Profiles';
import Orders from './OrderTracking';
import Help from './Help';
import Settings from './Settings';
import Contacts from './Contacts';
import Analytics from './Analytics';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useAllOrdersData from '../../../hooks/useAllOrdersData';
import useUserProfileIds from '../../../hooks/useUserProfileIds';
import useLastOrderId from '../../../hooks/useLastOrderId';

const Home = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [activePage, setActivePage] = useState('profiles');
  const orderId  = useLastOrderId();
  //console.log('Order ID:', orderId);
  const location = useLocation();
  const currentUser = useCurrentUser();
  const email = currentUser ? currentUser.email : '';
  const ordersData = useAllOrdersData();
  const profileIds = useUserProfileIds();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const userCount = ordersData.reduce((total, order) => total + order.userCount, 0);
  const profileCount = profileIds.length;

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get('page');
    if (page) {
      setActivePage(page);
    }
  }, [location.search]);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSidebarToggle = (open) => {
    setIsSidebarOpen(open);
  };

  const handleItemClick = (page) => {
    setActivePage(page);
  };

  const renderPage = () => {
    switch (activePage) {
      case 'profiles':
        return <Profiles orderId={orderId} userCount={userCount} profileCount={profileCount} />;
      case 'orders':
        return <Orders setActivePage={setActivePage} />;
      case 'contacts':
        return <Contacts />;
      case 'settings':
        return <Settings />;
      case 'help':
        return <Help />;
      case 'statistiques':
        return <Analytics />;
      default:
        return <Profiles orderId={orderId} userCount={userCount} profileCount={profileCount} />;
    }
  };

  const getSectionName = () => {
    switch (activePage) {
      case 'profiles':
        return 'Profils';
      case 'orders':
        return 'Mes Commandes';
      case 'contacts':
        return 'Contacts';
      case 'settings':
        return 'Paramétres';
      case 'help':
        return 'Aide';
      case 'statistiques':
        return 'Statistiques';
      default:
        return 'Profils';
    }
  };

  return (
    <div className='flex flex-col bg-gray-50 min-h-screen'>
      <div
        className={`fixed top-0 left-0 w-full bg-white text-purple-700 p-4 border border-gray-200 z-10 transition-all duration-300 ${isMobile ? '' : ` ${isSidebarOpen ? 'ml-[280px]' : 'ml-[68px]'}`}`}
      >
        <h1 className="text-lg font-bold">{getSectionName()}</h1>
        {orderId && userCount > 0 && (
          <div className='flex flex-row space-x-4'>
            <p className="text-sm font-bold text-gray-500">Solde:  </p>
            <p className='text-sm text-gray-700 font-semibold font-serif'>{userCount}</p>
            <p className='text-sm font-bold text-gray-500'>|</p>
            <p className='text-sm font-bold text-gray-500'>Profils créés: </p>
            <p className='text-sm text-gray-700 font-semibold font-serif'>{profileCount}/{userCount}</p>
          </div>
        )}
      </div>

      <div className='flex flex-1 mt-16'>
        <Sidebar
          email={email}
          onToggle={handleSidebarToggle}
          activePage={activePage}
          onItemClick={handleItemClick}
          className={`transition-all duration-300 ${isMobile ? 'w-full' : `${isSidebarOpen ? 'w-64' : 'w-14'}`}`}
        />
        <div className={`flex-1 p-4 transition-all duration-300 ${isMobile ? 'ml-0' : `${isSidebarOpen ? 'ml-64' : 'ml-14'}`} overflow-x-auto`}>
          {renderPage()}
        </div>
      </div>
    </div>
  );
};

export default Home;
