import React, { useEffect, useState } from 'react';
import 'tailwindcss/tailwind.css';
import { Link as ScrollLink } from 'react-scroll';
import FeedBack from "../LandingPage/Feedback";
import Fonctionnalite from './Fonctionnalite';
import Offre from "./Offre";
//import { eliteCardUrl } from '../../../constants';
import { Helmet } from 'react-helmet';
import eliteCardVideo from '../../../images/Elitecard-animation-LP.mp4';

const Acceuil = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const loggedIn = localStorage.getItem('isLoggedIn') === 'true';
    setIsLoggedIn(loggedIn);
  }, []);

  return (
    <div>
      <Helmet>
        <title>EliteCard</title>
        <meta name="description" content="Découvrez et partagez votre carte de visite numérique avec EliteCard. Simplifiez vos échanges professionnels avec une carte moderne et dynamique. " />
        <meta name="keywords" content="business card, digital, seo" />
        <meta name="author" content="EliteCard" />
        <meta property="og:title" content="EliteCard.ma" />
        <meta property="og:description" content="Découvrez et partagez votre carte de visite numérique avec EliteCard. Simplifiez vos échanges professionnels avec une carte moderne et dynamique." />
        <meta property="og:image" content="https://elitecard.ma/images/icon.png" />
        <meta property="og:url" content={process.env.REACT_APP_URL}/>
        <meta name="twitter:title" content="EliteCard.ma" />
        <meta name="twitter:description" content="Découvrez et partagez votre carte de visite numérique avec EliteCard. Simplifiez vos échanges professionnels avec une carte moderne et dynamique." />
        <meta name="twitter:image" content="https://elitecard.ma/images/icon.png" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      {/* Section 1 */}
      <div id="acceuil" className="flex-grow flex flex-col items-center justify-center bg-gradient-to-r from-blue-500 to-purple-500 py-12 lg:px-8 border mt-11">
        <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center justify-between relative">
          <div  style={{ width: '320px', height: '600px' }}>
            <video
              src={eliteCardVideo}
              title="EliteCard Video"
              autoPlay
              loop
              muted 
              className="rounded-lg w-full h-full"
            />
          </div>
          <div className="md:w-1/2 flex flex-col items-start ml-4 ">
            <h1 className="md:text-5xl text-3xl font-bold text-white mb-4">
              Redéfinissez vos Rencontres Professionnelles
            </h1>
            <p className="text-white mb-6">
              La plateforme incontournable pour transformer chaque interaction en opportunité de marque mémorable, tant pour les particuliers que pour les entreprises.
            </p>
            <div className="flex space-x-4 mb-6">
              <ScrollLink to="offre" smooth={true} duration={500}>
                <button className="bg-yellow-500 text-white px-6 py-3 rounded transition-all duration-300 ease-in-out hover:bg-yellow-400 transform hover:scale-105 shadow-lg">
                  Je veux mon EliteCard
                </button>
              </ScrollLink>
            </div>
            <div className="flex flex-col items-center mb-6">
              <div className="flex -space-x-2 overflow-hidden">
                <img
                  alt="Avatar 1"
                  src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  className="inline-block h-20 w-20 rounded-full ring-2 ring-white border-2 border-white"
                />
                <img
                  alt="Avatar 2"
                  src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  className="inline-block h-20 w-20 rounded-full ring-2 ring-white border-2 border-white"
                />
                <img
                  alt="Avatar 3"
                  src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
                  className="inline-block h-20 w-20 rounded-full ring-2 ring-white border-2 border-white"
                />
                <img
                  alt="Avatar 4"
                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  className="inline-block h-20 w-20 rounded-full ring-2 ring-white border-2 border-white"
                />
              </div>
              <p className="text-lg text-white mb-4 font-semibold mt-4">
                Vous aussi, faites le choix des Elites !
              </p>
            </div>
          </div>
        </div>
      </div>


      {/* Section 7 */}
      <section className="">
        <Fonctionnalite />
      </section>

      {/* Section 8 */}
      <div id="offre" className="mt-6 md:mt-32">
        <Offre />
      </div>

      {/* Section 9 */}
      <div id="Questions" >
        <FeedBack />
      </div>

    </div>
  );
};

export default Acceuil;
