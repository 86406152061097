import React, { useContext, createContext, useState, useEffect, useRef } from "react";
import { MoreVertical, ChevronLast, ChevronFirst, Contact, UserPen, Package, Settings, LifeBuoy, LogOut, User } from "lucide-react";
import { FaCircleUser } from "react-icons/fa6";
import { SiGoogleanalytics } from "react-icons/si";
import logo from "../../images/logo.png";
import { auth } from '../../firebase'; 
import { signOut } from 'firebase/auth';
//import { eliteCardUrl } from "../../constants";
import { PiListBold } from "react-icons/pi";
import { IoHomeOutline } from "react-icons/io5";
const SidebarContext = createContext();

export default function Sidebar({ email, onToggle, activePage, onItemClick }) {
  const [expanded, setExpanded] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Define mobile screen width
  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    if (isMobile) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMobile]);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      localStorage.clear(); 
      window.location.href = '/'; // Redirect to homepage after sign out
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const toggleExpanded = () => {
    setExpanded(curr => {
      const newExpandedState = !curr;
      if (onToggle) onToggle(newExpandedState);
      return newExpandedState;
    });
  };

  const toggleDropdown = () => {
    setDropdownOpen(prev => !prev);
  };

  const handleItemClick = (page) => {
    onItemClick(page);
    if (isMobile) {
      setDropdownOpen(false); // Close dropdown when item is clicked
    }
  };

  const handleCompte = () => {
    handleItemClick('settings');
  };

  const handleHomeClick = () => {
    window.location.href = '/'; // Redirection vers la page d'accueil
  };

  const sidebarItems = [
    { icon: <UserPen size={20} />, text: "Mes Profils", page: 'profiles' },
    { icon: <Package size={20} />, text: "Mes Commandes", page: 'orders' },
    { icon: <Contact size={20} />, text: "Mes Contacts (À venir)", page: 'contacts' },
    { icon: <SiGoogleanalytics size={20} />, text: "Statistiques (À venir)", page: 'statistiques' },
    { icon: <Settings size={20} />, text: "Paramètres", page: 'settings' },
    { icon: <IoHomeOutline size={20} />, text: "Revenir à la page d'accueil", page: 'home' } ,
    { icon: <LifeBuoy size={20} />, text: "Aide", page: 'help' }
  ];

  return (
    <aside ref={sidebarRef} className={`h-screen z-50 ${isMobile ? 'fixed top-4 right-2 ' : 'fixed top-0 left-0 bg-white border-r shadow-sm'}`}>
      {isMobile ? (
        <div className="relative">
          {/* Mobile menu button */}
          <button
            onClick={toggleDropdown}
            className="flex items-center p-2 rounded-lg bg-violet-50 hover:bg-violet-700  "
          >
            <PiListBold className="text-violet-600 hover:text-white" />
          </button>
          {/* Dropdown menu */}
          {dropdownOpen && (
            <ul className="absolute top-full right-0 mt-2 bg-white shadow-lg rounded-lg w-48">
              {sidebarItems.map((item, index) => (
                <li
                  key={index}
                  className="cursor-pointer text-left px-4 py-2 text-sm text-violet-700 hover:bg-violet-100 transition-colors duration-200 flex items-center gap-2"
                  onClick={item.page === 'home' ? handleHomeClick : () => handleItemClick(item.page)}
                >
                  {item.icon}
                  <span className="ml-3">{item.text}</span>
                </li>
              ))}
              <li className="cursor-pointer text-left px-4 py-2 text-sm text-violet-700 hover:bg-violet-100 transition-colors duration-200 flex items-center gap-2"
                  onClick={handleSignOut}
                >
                  <LogOut size={20} /> 
                  <span className="ml-3">Déconnecter</span>
                </li>
            </ul>
          )}
        </div>
      ) : (
        <nav className="h-full flex flex-col">
          {/* Desktop sidebar */}
          <div className="p-4 pb-2 flex justify-between items-center">
            <a href={process.env.REACT_APP_URL} className="flex items-center">
              <img
                src={logo}
                className={`overflow-hidden transition-all ${expanded ? "w-32 h-16" : "w-0"}`}
                alt="Logo"
              />
            </a>
            <button
              onClick={toggleExpanded}
              className="p-1.5 rounded-lg bg-gray-50 hover:bg-gray-100"
            >
              {expanded ? <ChevronFirst /> : <ChevronLast />}
            </button>
          </div>
  
          <SidebarContext.Provider value={{ expanded }}>
            <ul className="flex-1 px-3">
              {sidebarItems.map((item, index) => (
                <SidebarItem 
                  key={index} 
                  {...item} 
                  active={item.page === activePage}
                  onClick={() => item.page === 'home' ? handleHomeClick() : handleItemClick(item.page)} 
                />
              ))}
            </ul>
          </SidebarContext.Provider>
  
          <div className="border-t flex p-3">
            <div className="flex items-center justify-center rounded-full bg-violet-600 text-white w-9 h-9">
              <FaCircleUser />
            </div>
            <div
              className={`flex justify-between items-center overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"}`}
            >
              <div className="leading-4">
                <span className="text-sm text-gray-600">{email}</span>
              </div>
              <MoreVertical size={20} onClick={toggleDropdown} className="cursor-pointer" />
            </div>
            {dropdownOpen && (
              <div
                className="absolute right-4 bottom-2 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20 transition-opacity duration-300 ease-in-out transform origin-top-right scale-95"
                onMouseLeave={toggleDropdown} // Close dropdown on mouse leave
              >
                <div className="py-1">
                  <button
                    onClick={handleCompte}
                    className="w-full text-left px-4 py-2 text-sm text-violet-700 hover:bg-violet-100 transition-colors duration-200 flex items-center gap-2"
                  >
                    <User size={16}/>
                    Compte
                  </button>
  
                  <button
                    onClick={handleSignOut}
                    className=" w-full text-left px-4 py-2 text-sm text-violet-700 hover:bg-violet-100 transition-colors duration-200 flex items-center gap-2"
                  >
                    <LogOut size={16} /> 
                    Déconnecter
                  </button>
                </div>
              </div>
            )}
          </div>
        </nav>
      )}
    </aside>
  );
}

function SidebarItem({ icon, text, active, alert, onClick }) {
  const { expanded } = useContext(SidebarContext);

  return (
    <li
      onClick={onClick}
      className={`relative flex items-center py-2 px-3 my-1
      font-medium rounded-md cursor-pointer
      transition-colors group
      ${active ? "bg-gradient-to-tr from-violet-200 to-violet-100 text-violet-600" : "hover:bg-indigo-50 text-gray-600"}`}
    >
      {icon}
      <span className={`overflow-hidden transition-all ${expanded ? "ml-3" : "w-0"}`}>
        {text}
      </span>
      {alert && (
        <div className={`absolute right-2 w-2 h-2 rounded bg-violet-400 ${expanded ? "" : "top-2"}`} />
      )}

      {!expanded && (
        <div
          className={`absolute left-full rounded-md px-2 py-1 ml-6
          bg-indigo-100 text-violet-600 text-sm
          invisible opacity-20 -translate-x-3 transition-all
          group-hover:visible group-hover:opacity-100 group-hover:translate-x-0`}
        >
          {text}
        </div>
      )}
    </li>
  );
}
