import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { AiOutlineMail, AiOutlinePhone, AiOutlineGlobal, AiOutlineFacebook, AiOutlineInstagram } from 'react-icons/ai';
import { FaLinkedin, FaSnapchatGhost, FaPinterestSquare, FaTelegram, FaTiktok, FaWhatsapp, FaYoutube, FaLink, FaMapMarkerAlt } from 'react-icons/fa';
import useProfileData from '../../../hooks/useProfileData';
import { IoLocationOutline } from "react-icons/io5";
import { FaFilePdf  } from "react-icons/fa";

const GenericProfile = ({ profileData: initialProfileData, profileId, height = 200, radius = 80, onProfileDataChange, isSmallText = false }) => {
  const containerRef = useRef(null);
  const [width, setWidth] = useState(488);
  const { profileData: fetchedProfileData} = useProfileData(profileId);
  const [profileData, setProfileData] = useState(initialProfileData || null);

  useEffect(() => {
    if (!initialProfileData && fetchedProfileData) {
      setProfileData(fetchedProfileData);
    }
  }, [initialProfileData, fetchedProfileData]);

  useEffect(() => {
    if (initialProfileData) {
      setProfileData(initialProfileData);
    }
  }, [initialProfileData]);

  useEffect(() => {
    if (onProfileDataChange) {
      onProfileDataChange(profileData);
    }
  }, [profileData, onProfileDataChange]);
  useLayoutEffect(() => {
    if (containerRef.current) {
      setWidth(containerRef.current.offsetWidth);
    }
  }, []); 

  const upperPathData = `
    M 0,${height - height / 4}
    C ${width / 4},${height} ${width * 3 / 4},0 ${width},${height - height / 4}
    L ${width},0
    L 0,0
    Z
  `;
  const curvePathData = `
    M 0,${height - height / 4}
    C ${width / 4},${height} ${width * 3 / 4},0 ${width},${height - height / 4}
  `;
  if (!profileData) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-10 h-10 border-4 border-white border-dashed rounded-full animate-spin"></div>
      </div>
    );
  }

  const getIconForField = (fieldName) => {
    switch (fieldName) {
      case 'email':
        return <AiOutlineMail className="w-5 h-5" />;
      case 'phone':
        return <AiOutlinePhone className="w-5 h-5" />;
      case 'linkedin':
        return <FaLinkedin className="w-5 h-5" />;
      case 'website':
        return <AiOutlineGlobal className="w-5 h-5" />;
      case 'facebook':
        return <AiOutlineFacebook className="w-5 h-5" />;
      case 'instagram':
        return <AiOutlineInstagram className="w-5 h-5" />;
      case 'snapchat':
        return <FaSnapchatGhost className="w-5 h-5" />;
      case 'pinterest':
        return <FaPinterestSquare className="w-5 h-5" />;
      case 'telegram':
        return <FaTelegram className="w-5 h-5" />;
      case 'tiktok':
        return <FaTiktok className="w-5 h-5" />;
        case 'whatsapp':
        return <FaWhatsapp className="w-5 h-5" />;
      case 'youtube':
        return <FaYoutube className="w-5 h-5" />;
      case 'link':
        return <FaLink className="w-5 h-5" />;
      case 'address':
        return <FaMapMarkerAlt className="w-5 h-5" />;
        case 'pdf':
        return <FaFilePdf   className="w-5 h-5" />;
      default:
        return null;
    }
  };

  const textSizeClass = isSmallText ? 'text-xs' : '';
  return (
    <div className="mb-4 shadow-2xl rounded-3xl bg-white pb-2 overflow-hidden" ref={containerRef}>
      <svg width="100%" height="40%" viewBox={`0 0 ${width} ${height}`} xmlns="http://www.w3.org/2000/svg" className="rounded-t-xl rounded-b-xl bg-white">
        <path d={upperPathData} fill="#8f60de" />
        <path d={curvePathData} stroke="#8f60de" strokeWidth="2" fill="none" />

        <defs>
          <clipPath id="mask-profile">
            <circle cx={width / 2} cy={height / 2} r={radius} />
          </clipPath>
        </defs>

        {profileData.profilePhotoUrl && profileData.profilePhotoUrl !== null && (
          <image
            href={profileData.profilePhotoUrl}
            width={radius * 2}
            height={radius * 2}
            x={(width / 2) - radius}
            y={(height / 2) - radius}
            clipPath="url(#mask-profile)"
            preserveAspectRatio="xMidYMid slice"
          />
        )}
      </svg>

      <div className={`ml-8 mr-8 mb-8`}>
        <div className="mt-8 space-y-4 mb-8">
          <div className="relative">
            <div className="absolute h-full border-l-2 border-dashed border-green-900 left-0 ml-4"></div>
            <div className={`flex flex-col ml-8 mb-4 break-words whitespace-normal ${textSizeClass}`}>
              <h3 className={`md:text-2xl md:font-bold text-base font-semibold font-serif mb-2 ${textSizeClass}`}>{profileData.fullName}</h3>
              <div className={`w-2/3 `}>
                <p className={`text-gray-600 md:font-semibold font-medium mb-2 ${textSizeClass}`}>{profileData.title}</p>
                <p className={`text-violet-500 md:font-semibold font-medium mb-2 ${textSizeClass}`}>{profileData.departement}</p>
                <p className={`text-gray-500 italic md:font-bold font-semibold mb-4 break-all ${textSizeClass}`}>{profileData.company}</p>
              </div>
              
            </div>
            {profileData.logoUrl && profileData.logoUrl !== null && (
              <div className="absolute right-2 top-8 flex items-center justify-center w-16 h-16">
                <img
                  src={profileData.logoUrl}
                  className="h-16 w-16 rounded-full border-2 border-gray-100"
                />
              </div>
            )}

          </div>

          {profileData.aboutMe && (
            <div className="p-2 mb-4">
              <p className={`break-words whitespace-normal md:font-semibold text-gray-600 font-medium ${textSizeClass}`}>{profileData.aboutMe}</p>
            </div>
          )}

          {profileData.address && (
            <div className="flex items-center space-x-2 ">
              <div className="flex items-center justify-center bg-violet-600 text-2xl text-white w-9 h-9 rounded-full p-2">
                <IoLocationOutline />
              </div>
              <div className={`flex flex-col break-words whitespace-normal`}>
                <p className={`text-gray-500 font-medium ${textSizeClass}`}>{profileData.address}</p>
              </div>
            </div>
          )}


          {/* Affichage des champs */}
          {profileData.fields && profileData.fields.map((field, fieldIndex) => (
            <div key={fieldIndex} className="flex items-center space-x-2">
              <div className="flex flex-col space-y-1 break-words whitespace-normal">
                {field.instances.map((instance, instanceIndex) => (
                  <div key={`${field.name}-${instanceIndex}`} className={`flex items-center space-x-2 break-words whitespace-normal break-all ${textSizeClass}`}>
                    {getIconForField(field.name) && (
                      <div className="flex items-center justify-center rounded-full bg-violet-600 text-white w-9 h-9 p-2">
                        {getIconForField(field.name)}
                      </div>
                    )}
                    {field.name === 'email' ? (
                      <div className="flex flex-col break-words whitespace-normal">
                        <a
                          href={`mailto:${instance.value}`}
                          className={`text-violet-500 hover:underline break-words whitespace-normal break-all font-medium ${textSizeClass}`}
                        >
                          {instance.value}
                        </a>
                        {instance.label && (
                          <div className={`text-sm font-medium text-gray-600 break-words whitespace-normal ${textSizeClass}`}>{instance.label}</div>
                        )}
                      </div>
                    ) : field.name === 'phone' ? (
                      <div className="flex flex-col break-words whitespace-normal">
                        <a
                          href={`tel:${instance.value}`}
                          className={`text-violet-500 hover:underline break-words whitespace-normal break-all font-medium ${textSizeClass}`}
                        >
                          {instance.value}
                        </a>
                        {instance.label && (
                          <div className={`text-sm font-medium text-gray-600 break-words whitespace-normal ${textSizeClass}`}>{instance.label}</div>
                        )}
                      </div>
                    ) : field.name === 'address' ? (
                      <div className="flex flex-col break-words whitespace-normal">
                        <p className={`break-all font-medium ${textSizeClass}`}>{instance.value}</p>
                        {instance.label && (
                          <div className={`text-sm font-medium text-gray-600 break-words whitespace-normal ${textSizeClass}`}>{instance.label}</div>
                        )}
                      </div>
                    ) : field.name === 'whatsapp' ? (
                      <a
                        href={`https://api.whatsapp.com/send?phone=${instance.value}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`text-violet-500 hover:underline break-words whitespace-normal break-all font-medium ${textSizeClass}`}
                      >
                        {instance.label}
                      </a>                    
                    ) : (
                      <a
                        href={instance.value.startsWith('http') ? instance.value : '#'}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`text-violet-500 hover:underline break-words whitespace-normal break-all font-medium ${textSizeClass}`}
                      >
                        {instance.label}      
                      </a>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

GenericProfile.propTypes = {
  profileData: PropTypes.object,
  profileId: PropTypes.string,
  userId: PropTypes.string,
  height: PropTypes.number,
  radius: PropTypes.number,
  onProfileDataChange: PropTypes.func,
  isSmallText: PropTypes.bool
};

export default GenericProfile;
