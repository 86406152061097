import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FiSearch } from 'react-icons/fi';
import { FaEnvelope, FaPhone, FaLinkedin, FaGlobe, FaFacebook, FaInstagram, FaSnapchat, FaPinterest, FaTelegramPlane, FaTiktok, FaWhatsapp, FaLink, FaFilePdf, FaMapMarkerAlt, FaYoutube } from 'react-icons/fa';

const getIcon = (name) => {
  switch (name) {
    case 'email':
      return <FaEnvelope className="text-xs" />;
    case 'phone':
      return <FaPhone className="text-xs" />;
    case 'whatsapp':
      return <FaWhatsapp className="text-xs" />;
    case 'linkedin':
      return <FaLinkedin className="text-xs" />;
    case 'website':
      return <FaGlobe className="text-xs" />;
    case 'facebook':
      return <FaFacebook className="text-xs" />;
    case 'instagram':
      return <FaInstagram className="text-xs" />;
    case 'tiktok':
      return <FaTiktok className="text-xs" />;
    case 'address':
      return <FaMapMarkerAlt className="text-xs" />;
    case 'youtube':
      return <FaYoutube className="text-xs" />;
    case 'pdf':
      return <FaFilePdf className="text-xs" />;
    case 'link':
      return <FaLink className="text-xs" />;
    case 'snapchat':
      return <FaSnapchat className="text-xs" />;
    case 'pinterest':
      return <FaPinterest className="text-xs" />;
    case 'telegram':
      return <FaTelegramPlane className="text-xs" />;
    default:
      return null;
  }
};

const FieldsListEdit = ({ onSelectField }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const fields = [
    { label: 'Email', name: 'email' },
    { label: 'Téléphone', name: 'phone' },
    { label: 'WhatsApp', name: 'whatsapp' },
    { label: 'LinkedIn', name: 'linkedin' },
    { label: 'Site Web', name: 'website' },
    { label: 'Facebook', name: 'facebook' },
    { label: 'Instagram', name: 'instagram' },
    { label: 'TikTok', name: 'tiktok' },
    { label: 'Adresse', name: 'address' },
    { label: 'YouTube', name: 'youtube' },
    { label: 'PDF', name: 'pdf' },
    { label: 'Lien', name: 'link' },
    { label: 'Snapchat', name: 'snapchat' },
    { label: 'Pinterest', name: 'pinterest' },
    { label: 'Telegram', name: 'telegram' },
  ];

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredFields = searchQuery.trim() === '' ? fields : fields.filter(field =>
    field.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="w-full p-2 bg-gray-100 md:p-4 lg:p-4">
      <h2 className="text-lg md:text-xl lg:text-2xl font-bold mb-2 md:mb-4 flex items-center">
        Fields
      </h2>
      <div className="md:mb-4 mb-2 flex items-center border border-gray-300 rounded-md px-3 py-2 md:px-4 md:py-2 bg-white">
        <FiSearch className="text-gray-500" />
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Rechercher un champ..."
          className="ml-2 w-full focus:outline-none text-xs md:text-base"
        />
      </div>
      
      <div className="flex flex-wrap gap-2 md:flex-col md:gap-4">
        {filteredFields.map((field) => (
          <button
            key={field.name}
            className="flex items-center px-3 py-2 text-left bg-white rounded-md shadow-md hover:bg-gray-200 text-xs md:text-base"
            onClick={() => onSelectField(field)}
          >
            <div className="mr-1 md:mr-2">{getIcon(field.name)}</div>
            <span>{field.label}</span>
          </button>
        ))}
        {filteredFields.length === 0 && (
          <p className="text-gray-600">Aucun champ trouvé.</p>
        )}
      </div>
    </div>
  );
};

FieldsListEdit.propTypes = {
  onSelectField: PropTypes.func.isRequired,
};

export default FieldsListEdit;
