import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BackImg from "../../../images/BackImg.png"


const AfficheInfo = () => {
  const [userInfo, setUserInfo] = useState({
    userEmail: '',
    userCount: 0,
    plan: null,
    option: '',
  });

  useEffect(() => {
    const userEmail = localStorage.getItem('email');
    const userCount = localStorage.getItem('userCount');
    const plan = JSON.parse(localStorage.getItem('plan'));
    const option = JSON.parse(localStorage.getItem('Option'));

    console.log('Retrieved from localStorage:', {
      userEmail,
      userCount,
      plan,
      option,
    });

    setUserInfo({
      userEmail,
      userCount,
      plan,
      option,
    });
  }, []);

  const handleConfirm = () => {
    console.log('Option in handleConfirm:', userInfo.option);
    switch (userInfo.option) {
      case 'card':
        return '/PaymentForm';
      case 'bank_transfer': 
        return '/WaitPage';
      default:
        return '/'; 
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4 -m-20">
      <div className="flex justify-end mr-4 sm:mr-96 -ml-96">
  <Link to='/offre'>
    <img src={BackImg} className="w-12 h-12 sm:w-16 sm:h-16" alt="Icon" />
  </Link>
</div>
      <div className="w-full max-w-md bg-white shadow-lg rounded-lg overflow-hidden p-8">
        <h2 className="text-2xl font-bold text-center mb-6">Confirm Your Information</h2>

        <div className="grid grid-cols-1 gap-4">
          <div>
            <label className="block text-gray-700 font-semibold mb-2">Email:</label>
            <p className="text-gray-900 font-medium">{userInfo.userEmail}</p>
          </div>

          <div>
            <label className="block text-gray-700 font-semibold mb-2">Option:</label>
            <p className="text-gray-900 font-medium">{userInfo.option}</p>
          </div>

          <div>
            <label className="block text-gray-700 font-semibold mb-2">User Count:</label>
            <p className="text-gray-900 font-medium">{userInfo.userCount}</p>
          </div>

          {userInfo.plan && (
            <div>
              <label className="block text-gray-700 font-semibold mb-2">Plan:</label>
              <div className="border rounded-lg p-2">
                <p className="text-gray-900 font-medium">
                  <strong>Name:</strong> {userInfo.plan.name}
                </p>
                <p className="text-gray-900 font-medium">
                  <strong>Price:</strong> {userInfo.plan.price} Dhs
                </p>
                <p className="text-gray-900 font-medium">
                  <strong>Description:</strong> {userInfo.plan.description}
                </p>
              </div>
            </div>
          )}
        </div>

        <div className="flex justify-between mt-8">
          <Link to="/ConfirmPlan">
            <button
              type="button"
              className="bg-gray-300 text-gray-700 font-semibold py-2 rounded-lg hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 w-full mr-2"
            >
              Cancel
            </button>
          </Link>

          <Link to={handleConfirm()}>
            <button
              type="button"
              className="bg-purple-600 text-white font-semibold py-2 rounded-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white w-full ml-2"
            >
              Confirm
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AfficheInfo;
