import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth'; // Assure-toi que Firebase est configuré
import call from "../../../images/call.png";
import Card from "../../../images/Card.png";
import { cardData, pricingDetails } from '../../Element/Data';
import useAllOrdersData from '../../../hooks/useAllOrdersData';
import useUserProfileIds from '../../../hooks/useUserProfileIds';

const PricingDetailCard = ({ title, oldPrice, newPrice, endDate, buttonText, buttonLink, icon, onSelect, isLoggedIn }) => {
  const isSpecialCard = title === '+50 Cartes';
  const cardClass = isSpecialCard
    ? 'bg-gradient-to-br from-purple-600 to-purple-400 text-white'
    : 'bg-white text-gray-900';

  const handleButtonClick = () => {
    if (isLoggedIn || isSpecialCard) {
      window.location.href = buttonLink;
    } else {
      window.location.href = "/Register";
    }
  };

  return (
    <div className={`w-full md:w-96 lg:w-80 mx-auto rounded-xl shadow-lg overflow-hidden transition-transform transform hover:scale-105 ${cardClass}`}>
      <div className={`p-6 flex flex-col h-full ${isSpecialCard ? 'text-white' : 'text-gray-900'}`}>
        <div className="flex-1">
          <h1 className={`text-3xl font-bold mb-4 ${isSpecialCard ? 'text-white' : 'text-purple-600'}`}>
            {icon} {title}
          </h1>
          {isSpecialCard ? (
            <>
              <p className="text-gray-100 mb-6">
                Un de nos conseillers vous guidera pour traiter votre besoin
              </p>
              <img
                src={call}
                className="w-28 h-28 rounded-full mb-6 mx-auto"
                alt="Personne à appeler"
              />
            </>
          ) : (
            <>
              <p className="text-gray-600 mb-2 text-lg">
                <span className="text-xl">🏷️</span> Offre valable avant {endDate}
              </p>
              <div className='flex flex-row items-center'>
                <div className="flex flex-col space-y-2">
                  <span className="text-2xl font-bold text-green-500">{newPrice} Dhs</span>
                  <span className="text-lg line-through">{oldPrice} Dhs</span>
                </div>
                <div className='bg-green-500 animate-bounce ml-8 rounded-lg h-6 w-36'>
                  <p className="text-sm text-center text-white">Livraison gratuite</p>
                </div>
              </div>
              <img
                src={Card}
                alt="Carte"
                className="w-full h-auto transition-transform duration-300 ease-in-out transform hover:scale-105 hover:rotate-3"
              />
            </>
          )}
        </div>
        <button
          onClick={() => {
            onSelect(title);
            handleButtonClick();
          }}
          className={`self-center w-full max-w-xs py-3 px-6 rounded-full text-center transition-transform transform hover:scale-105 ${isSpecialCard ? 'bg-white text-gray-900 hover:bg-gray-300' : 'bg-purple-600 text-white hover:bg-purple-700'}`}
        >
          {buttonText} {isSpecialCard ? '' : '🚀'}
        </button>
      </div>
    </div>
  );
};

const PricingCard = () => {
  const auth = getAuth();
  const [currentUser, setCurrentUser] = useState(null);
  const ordersData = useAllOrdersData();
  const profileIds = useUserProfileIds();

  const userCount = ordersData.reduce((total, order) => total + order.userCount, 0);
  const profileCount = profileIds.length;

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setCurrentUser(user);
    });

    return () => unsubscribe();
  }, [auth]);

  const handleCardSelection = (title) => {
    localStorage.setItem('selectedCard', title);
  };

  const isLoggedIn = !!currentUser; // Vérifie si l'utilisateur est connecté

  return (
    <div className="bg-purple-100 p-12 rounded-lg shadow-xl max-w-screen-xl mx-auto animate-fade-in-up">
      <h1 className="text-6xl font-bold text-purple-800 mb-12 text-center animate-bounce">
        Notre Offre 💎
      </h1>
      <div className="flex flex-col md:flex-row items-center justify-center space-y-8 md:space-y-0 md:space-x-10 mb-12">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 text-black-600">
          {cardData.map((item, index) => (
            <div key={index} className="relative flex items-center space-x-4 transition-transform transform hover:scale-105">
              <span className="text-4xl">{item.icon}</span>
              <span className="text-xl font-semibold flex-1">{item.text}</span>
              {item.comingSoon && (
                <div className="absolute top-7 right-0 bg-green-500 text-white text-xs px-2 py-1 rounded-full transform translate-x-4 -translate-y-2">
                  Bientôt Disponible
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-wrap justify-center gap-8">
        {pricingDetails.map((detail, index) => (
          <PricingDetailCard 
            key={index}
            title={detail.title}
            oldPrice={detail.oldPrice}
            newPrice={detail.newPrice}
            endDate={detail.endDate}
            buttonText={detail.buttonText}
            buttonLink={detail.title !== '+50 Cartes' && profileCount < userCount ? '/espaceClient' : detail.buttonLink}
            icon={detail.icon}
            onSelect={handleCardSelection} 
            isLoggedIn={isLoggedIn} // Passer l'état d'authentification à l'enfant
          />
        ))}
      </div>
    </div>
  );
};

export default PricingCard;
