import { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import useCurrentUser from './useCurrentUser';

const useOrderData = (orderId) => {
  const [orderData, setOrderData] = useState(null);
  const currentUser = useCurrentUser();

  useEffect(() => {
    const fetchOrderData = async () => {
      if (!currentUser) {
        console.log('No user is logged in');
        return;
      }

      try {
        const docRef = doc(db, 'orders', orderId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setOrderData(docSnap.data());
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching order data:', error);
      }
    };

    fetchOrderData();
  }, [orderId, currentUser]);

  return orderData;
};

export default useOrderData;
