import React from 'react';
import { FiPhone } from 'react-icons/fi';
import BackImg from "../../../images/BackImg.png";
import { Link } from "react-router-dom";

const userProfile = JSON.parse(localStorage.getItem('userProfile')) || {};
const userName = userProfile.fullName || 'cher(e) client(e)';
const selectedCard = localStorage.getItem('selectedCard');

const WelcomePage = () => {
  const numberOfCards = parseInt(selectedCard, 10);

  return (
    <div className="flex flex-col justify-center min-h-screen bg-gradient-to-r from-blue-500 to-purple-500 p-4">
      <Link to='/ContactForm' className='absolute top-4 left-4 md:static mb-8 md:mb-4'>
        <img src={BackImg} className="w-8 h-8 md:w-12 md:h-12" alt="Icon" />
      </Link>
      <div className="max-w-4xl w-full md:w-[80%] mx-auto p-4 md:p-6 bg-white rounded-lg shadow-lg flex flex-col md:flex-row mb-12 md:mb-20">
        <div className="relative w-full h-48 md:h-[500px]">
          <img
            src="https://images.pexels.com/photos/7709263/pexels-photo-7709263.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt="Welcome Image"
            className="absolute inset-0 w-full h-full object-cover rounded-lg"
          />
        </div>
        <div className="w-full md:w-1/2 px-4 md:px-6 py-4 flex flex-col justify-center">
          {numberOfCards > 50 ? (
            <>
              <h2 className="text-xl md:text-3xl font-bold text-gray-800 mb-4">
                Votre demande a bien été reçue.
              </h2>
              <p className="text-gray-700 mb-4">
                Nous vous contacterons sous peu pour vous guider dans le traitement de votre besoin.
              </p>
              <p className="text-gray-700 mb-4">
                Si vous êtes pressé, n'hésitez pas à nous appeler au 0714515566 ou à envoyer un email à EliteCard.Support@gmail.com. Pour plus d'informations sur nos services et produits, visitez notre site web à www.elitecard.ma.
              </p>
            </>
          ) : (
            <>
              <h2 className="text-xl md:text-3xl font-bold text-gray-800 mb-4">
                Merci pour votre intérêt à notre super Elite Card, {userName}!
              </h2>
              <p className="text-gray-700 mb-4">
                Nous avons bien reçu votre demande. Un conseiller vous contactera bientôt pour plus d'informations.
              </p>
              <p className="text-gray-700 mb-4">
                Pour toute question ou pour nous contacter directement, appelez-nous au numéro suivant :
              </p>
              <a href="tel:+33660094594" className="inline-flex items-center px-4 py-2 bg-purple-600 text-white rounded-lg shadow-lg hover:bg-purple-700">
                <FiPhone className="w-5 h-5 mr-1" />
                Appeler maintenant
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
