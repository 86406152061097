import React, { useState } from "react";
import { payWithCard } from "./PaymentFunction";
import { FaCreditCard } from "react-icons/fa";
import BackImg from "../../../images/BackImg.png";
import { Link } from "react-router-dom";


const PaymentForm = () => {
  const [formData, setFormData] = useState({
    amount: "",
    demandeId: "",
    expireDate: "",
    creditCard: "",
    cvv: "",
    cardHolderName: "",
  });

  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState("");

  const validate = () => {
    const errors = {};

    if (!formData.amount) {
      errors.amount = "Le montant est requis";
    } else if (isNaN(formData.amount)) {
      errors.amount = "Le montant doit être un nombre";
    }

    if (!formData.demandeId) {
      errors.demandeId = "L'ID de la demande est requis";
    } else if (isNaN(formData.demandeId)) {
      errors.demandeId = "L'ID de la demande doit être un nombre";
    }

    if (!formData.expireDate) {
      errors.expireDate = "La date d'expiration est requise";
    }

    if (!formData.creditCard) {
      errors.creditCard = "Le numéro de carte de crédit est requis";
    }

    if (!formData.cvv) {
      errors.cvv = "Le CVV est requis";
    } else if (isNaN(formData.cvv)) {
      errors.cvv = "Le CVV doit être un nombre";
    }

    if (!formData.cardHolderName) {
      errors.cardHolderName = "Le nom du titulaire de la carte est requis";
    }

    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await payWithCard(
          { amount: formData.amount, demandeId: formData.demandeId },
          {
            expireDate: formData.expireDate,
            creditCard: formData.creditCard,
            cvv: formData.cvv,
            cardHolderName: formData.cardHolderName,
          }
        );
        setSuccess("Paiement réussi!");
        console.log("Paiement réussi:", response);
      } catch (error) {
        console.error("Erreur de paiement:", error);
        setErrors({ api: "Erreur de paiement. Veuillez réessayer." });
      }
    }
  };

  return (
    
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-blue-500 to-purple-500 p-4">
      
      <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-md">
          <Link to='/ConfirmPlan'>
            <img src={BackImg} className="w-12 h-12" alt="Icon" />
          </Link>
      <div className="flex items-center justify-between mb-6">
        </div>
        <h1 className="text-2xl font-bold mb-6 text-center">Paiement</h1>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block mb-1 text-sm font-medium text-gray-700">Montant</label>
            <input
              type="text"
              name="amount"
              value={formData.amount}
              onChange={handleChange}
              className={`w-full px-4 py-2 border rounded-lg focus:outline-none transition duration-300 ${
                errors.amount ? "border-red-500" : "border-gray-300"
              }`}
            />
            {errors.amount && <p className="text-red-500 text-xs mt-1">{errors.amount}</p>}
          </div>

          <div>
            <label className="block mb-1 text-sm font-medium text-gray-700">ID de la demande</label>
            <input
              type="text"
              name="demandeId"
              value={formData.demandeId}
              onChange={handleChange}
              className={`w-full px-4 py-2 border rounded-lg focus:outline-none transition duration-300 ${
                errors.demandeId ? "border-red-500" : "border-gray-300"
              }`}
            />
            {errors.demandeId && <p className="text-red-500 text-xs mt-1">{errors.demandeId}</p>}
          </div>

          <div>
            <label className="block mb-1 text-sm font-medium text-gray-700">Date d'expiration</label>
            <input
              type="text"
              name="expireDate"
              value={formData.expireDate}
              onChange={handleChange}
              className={`w-full px-4 py-2 border rounded-lg focus:outline-none transition duration-300 ${
                errors.expireDate ? "border-red-500" : "border-gray-300"
              }`}
            />
            {errors.expireDate && <p className="text-red-500 text-xs mt-1">{errors.expireDate}</p>}
          </div>

          <div>
            <label className="block mb-1 text-sm font-medium text-gray-700">Numéro de carte de crédit</label>
            <input
              type="text"
              name="creditCard"
              value={formData.creditCard}
              onChange={handleChange}
              className={`w-full px-4 py-2 border rounded-lg focus:outline-none transition duration-300 ${
                errors.creditCard ? "border-red-500" : "border-gray-300"
              }`}
            />
            {errors.creditCard && <p className="text-red-500 text-xs mt-1">{errors.creditCard}</p>}
          </div>

          <div>
            <label className="block mb-1 text-sm font-medium text-gray-700">CVV</label>
            <input
              type="text"
              name="cvv"
              value={formData.cvv}
              onChange={handleChange}
              className={`w-full px-4 py-2 border rounded-lg focus:outline-none transition duration-300 ${
                errors.cvv ? "border-red-500" : "border-gray-300"
              }`}
            />
            {errors.cvv && <p className="text-red-500 text-xs mt-1">{errors.cvv}</p>}
          </div>

          <div>
            <label className="block mb-1 text-sm font-medium text-gray-700">Nom du titulaire de la carte</label>
            <input
              type="text"
              name="cardHolderName"
              value={formData.cardHolderName}
              onChange={handleChange}
              className={`w-full px-4 py-2 border rounded-lg focus:outline-none transition duration-300 ${
                errors.cardHolderName ? "border-red-500" : "border-gray-300"
              }`}
            />
            {errors.cardHolderName && (
              <p className="text-red-500 text-xs mt-1">{errors.cardHolderName}</p>
            )}
          </div>

          {errors.api && <p className="text-red-500 text-xs mt-1">{errors.api}</p>}

          {success && <p className="text-green-500 text-xs mt-1">{success}</p>}

          <div>
            <button
              type="submit"
              className="w-full px-4 py-2 text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-300"
            >
              <FaCreditCard className="inline-block mr-2" />
              Payer
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PaymentForm;
