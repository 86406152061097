import React from 'react';


const Contacts = () => {
  return (
    <div className="p-4">
      <h1>Mes Contacts</h1>

    </div>
  );

  
};

export default Contacts;
